import React, {useState, useContext} from "react"
import {Modal} from './Default/Modal'
import {AuthContext} from "../Auth/AuthContext"
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next"

export interface Option {
    isActive: boolean,
    handleModal: any,
    handleModalForgot: any
}

const ModalLogin = (props: Option) => {
    const {t} = useTranslation()
    const {login, socialLogin} = useContext(AuthContext)

    const authSocial = () => {
        socialLogin(props.handleModal)
    }
    const handleForgot = () => {
        props.handleModal()
        props.handleModalForgot(true)
    }

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async (event: any) => {
        login(username, password, props.handleModal)
        event.preventDefault()
    }

    return (
        <Modal isOpen={props.isActive} onClose={props.handleModal}>
            <div className="modal_profile_title">{t('Sign in')}</div>
            <div className="modal_profile_description">{t('No account')}?</div>
            <div className="modal_profile_reg"><Link to="/registration"
                                                     onClick={props.handleModal}>{t('Register')}</Link></div>
            <div className="modal_profile_form">
                <div className="modal_profile_input_block">
                    <input type="text" className="modal_profile_input" id="email"
                           value={username}
                           autoFocus
                           onChange={(e) => setUsername(e.target.value)}
                           placeholder={t('Phone number or Email')}/>
                </div>
                <div className="modal_profile_input_block">
                    <input type="password" className="modal_profile_input" id="password"
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           placeholder={t('Enter password')}/>
                </div>
            </div>
            <div className="modal_profile_active">
                <div className="modal_profile_reset"><span onClick={handleForgot}>{t('Forgot your password')}</span>
                </div>
            </div>
            <div className="modal_profile_btn btn_bg btn" onClick={handleSubmit}>{t('Sign in')}</div>
            <div className="modal_profile_social">
                <div className="modal_profile_social_title">{t('Login with')}:</div>
                <div className="modal_profile_social_icon">
                    <div className="modal_profile_si_item vk" onClick={authSocial}></div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalLogin
