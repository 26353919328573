import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../../../component/Auth/AuthContext";
import axios from "axios";
import {ScrollingCarousel} from '@trendyol-js/react-carousel';
import ModalAddBackground from "../../../../component/Modal/ModalAddBackground";
import Select from 'react-select';
import ModalAddFriends from "../../../../component/Modal/ModalAddFriends";
import ModalMessages from "../../../../component/Modal/ModalSuccess";
import ModalSuccess from "../../../../component/Modal/ModalSuccess";
import html2canvas from "html2canvas";
import header from "../../../../component/Header/Header";


interface Option {
    handleModalLogin: any,
    selectFriendsTopSearch: any
}


export const Ticket = (props: Option) => {
    const {t} = useTranslation();
    const {drawCurrent, isAuthenticated, isMobile, name, avatar, token, getBasketTicket} = useContext(AuthContext);
    const [ticketBackground, setTicketBackground] = useState('');
    const [dateTicket, setDateTicket] = useState('');
    const [drawId, setDrawId] = useState(null);
    const [ticketCol, setTicketCol] = useState(1);
    const [ticketsNumberArray, setTicketsNumberArray] = useState(['']);
    const [selectedDraw, setSelectedDraw] = useState({value: 0});
    const [isActiveBg, setIsActiveBg] = useState(false);
    const [deleteFr, setDeleteFr] = useState(false);
    const [options, setOptions] = useState([]);
    const [isActiveFriends, setIsActiveFriends] = useState(false);
    const [selectFriendsID, setSelectFriendsID] = useState([]);
    const [ticketComment, setTicketComment] = useState('');
    const [isShowAdd, setIsShowAdd] = useState(true)
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [messagesModal, setMessagesModal] = useState('');

    const initTicket = () => {
        let drawCurrentList = drawCurrent;
        let drawCurrentLast = drawCurrentList[drawCurrentList.length - 1];
        let id = drawCurrentLast.drawraffle_id
        setDateTicket(new Date(drawCurrentLast.datestart).toLocaleString().split(',')[0])
        setDrawId(id)
        let newCollection = drawCurrentList.map((item: any) => {
            let {name, drawraffle_id, ...rest} = item;
            return {
                label: name,
                value: drawraffle_id,
                ...rest
            };
        });
        setSelectedDraw(newCollection[newCollection.length - 1])
        setOptions(newCollection)
    }

    const handleModalBg = () => {
        setIsActiveBg(current => !current);
    };

    const getCookies = (name: string) => {
        let res = '';
        document.cookie.split(';').forEach(c => {
            if (c.trim().startsWith(name))
                res += decodeURIComponent(c) + '';
        });
        return res;
    }
    const getTicketNumber = (id: any) => {
        let defaultTokenSl = ''
        if (getCookies('auth_token').split('=')[1]) {
            defaultTokenSl = getCookies('auth_token').split('=')[1];
        } else {
            defaultTokenSl = getCookies('sl-auth-token').split('=')[1];
        }

        axios.post('https://massaccess.net:9060/public/randomnumberdrawraffle', {"drawraffle_id": id}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + defaultTokenSl,
            }
        }).then((data) => {
            setTicketsNumberArray([])
            if (ticketsNumberArray.length == 1 && ticketsNumberArray[0] == '') {
                setTicketsNumberArray(() => [data.data.data.num])
            } else {
                setTicketsNumberArray((arr: any) => [...arr, data.data.data.num])
            }
        }).catch(err => {
            console.error(err);
        });
    }
    const getTicketNumberAdd = (id: any) => {
        let defaultTokenSl = ''
        if (getCookies('auth_token').split('=')[1]) {
            defaultTokenSl = getCookies('auth_token').split('=')[1];
        } else {
            defaultTokenSl = getCookies('sl-auth-token').split('=')[1];
        }

        axios.post('https://massaccess.net:9060/public/randomnumberdrawraffle', {"drawraffle_id": id}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + defaultTokenSl,
            }
        }).then((data) => {
            setTicketsNumberArray((arr: any) => [...arr, data.data.data.num])
        }).catch(err => {
            console.error(err);
        });
    }

    let base64 = '';
    const changeTicketBackground = (img: any) => {
        setTicketBackground(img)
    }
    const setInBasket = () => {
        if (isAuthenticated) {
            let tickets: any[] = [];
            let selectFr = selectFriendsID.map((item: any) => {
                return item.friends_id
            })
            ticketsNumberArray.map((item) => {
                if (item) {
                    tickets.push(
                        {
                            'frends_id': selectFr,
                            'ticketbackground': ticketBackground,
                            'ticketnumber': item,
                        }
                    )
                }
            })
            let basketticket = {
                "drawraffle_id": drawId,
                "tickets": tickets,
                'ticketcomment': ticketComment
            }
            axios.post('https://massaccess.net:9060/public/basketticketins', {"basketticket": basketticket}, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    Authorization: 'Bearer ' + token,
                }
            }).then((data) => {
                // @ts-ignore
                let i = document.getElementById('ticketItem').cloneNode(true);
                // @ts-ignore
                document.getElementById("copyBlock").appendChild(i);
                // @ts-ignore
                let top = document.getElementById('ticketItem').getBoundingClientRect().top;
                // @ts-ignore
                document.getElementById('copyBlock').style.top = top + 'px';
                // @ts-ignore
                setTimeout(() => {
                    // @ts-ignore
                    document.getElementById('copyBlock').classList.add('buy');
                    // @ts-ignore

                }, 100)
                setTimeout(() => {
                    // @ts-ignore
                    copyBlock.innerHTML = ''
                    // @ts-ignore
                    document.getElementById('copyBlock').classList.remove('buy');
                }, 700)
                setTicketsNumberArray([])
                setTicketCol(1)
                initTicket()
                getBasketTicket()
            }).catch(err => {
                console.error(err);
            });
        } else {
            props.handleModalLogin()
        }
    }

    const handleModal = () => {
        setIsActiveModal(current => !current)
    }
    const ticketColUp = () => {
        setTicketCol(ticketCol + 1)
        getTicketNumberAdd(drawId)
    }

    const ticketColDown = () => {
        setTicketCol(ticketCol > 1 ? ticketCol - 1 : ticketCol)
        setTicketsNumberArray((arr) => (ticketCol > 1 ? arr.slice(0, -1) : arr))
    }
    const handleModalFriends = () => {
        setIsActiveFriends(current => !current);
    };

    let firstRun = true;
    useEffect(() => {
        if (firstRun) {
            firstRun = false;
            setTicketsNumberArray([])
            setTicketCol(1)
            getTicketNumber(selectedDraw.value)
        }
    }, [selectedDraw])
    useEffect(() => {
        if (drawCurrent && drawCurrent.length > 0) {
            initTicket()
        }
    }, [drawCurrent]);
    useEffect(() => {
        if (props.selectFriendsTopSearch && props.selectFriendsTopSearch.friends_id != '') {
            if (selectFriendsID.length <= 2) {
                setIsShowAdd(false)
                addFriend(props.selectFriendsTopSearch)
            } else {
                setMessagesModal('You cannot add more than four participants')
                setIsActiveModal(true)
            }
        }
    }, [props.selectFriendsTopSearch]);
    const addFriend = (e: any) => {
        let isDub = true;
        selectFriendsID.map((item: any) => {
            if (item.friends_id === e.friends_id) {
                isDub = false
            }
        })
        if (isDub) {
            // @ts-ignore
            setSelectFriendsID([...selectFriendsID, e])
        }
        setDeleteFr(true)
    }
    const delFriends = (e: any) => {
        let id_client = e.target.dataset.c
        let arrDel = selectFriendsID.filter((item: any) => {
            return item.friends_id !== Number(id_client) && item.client_id !== Number(id_client)
        })
        setSelectFriendsID(arrDel)
        setDeleteFr(false)
    }
    useEffect(() => {
        if (deleteFr) {
            if (isShowAdd) {
                handleModalFriends()
            }
            setIsShowAdd(true)
            setDeleteFr(false)
        }
    }, [selectFriendsID]);


    return (
        <div>
            <div className="ticket_block" id="tBt">
                <div className="ticket_item" id="ticketItem">
                    <div className="ticket_item_bg" id="ticket_item_bg">
                        {ticketBackground ? <img src={ticketBackground ? ticketBackground : ''}/> : ''}
                    </div>
                    <div className="ticket_header">
                        <div className="ticket_input">
                            <input type="text" placeholder={t('Your wishes')} onChange={(e: any) => {
                                setTicketComment(e.target.value)
                            }}/>
                        </div>
                        <div className="ticket_drawraffles">
                            <Select
                                value={selectedDraw}
                                onChange={(e: any) => {
                                    setSelectedDraw(e)
                                }}
                                options={options}
                                isSearchable={false}
                                classNamePrefix={'salut'}
                                className={'salutSelect'}
                            />
                        </div>
                    </div>
                    <div className="ticket_content">
                        <div className="ticket_content_list">
                            <div className="ticket_user">
                                <div className="ticket_user_circle">
                                    <div className="ticket_user_circle_img">
                                        {isAuthenticated ?
                                            <img src={avatar ? avatar : ''}></img>
                                            : ''}
                                        {!isAuthenticated ?
                                            <div className="ticket_user_circle_img_login"
                                                 onClick={props.handleModalLogin}>{t('Sign in')}</div>
                                            : ''}
                                    </div>
                                    <div className="ticket_user_circle_text_href"></div>
                                </div>
                                <div className="ticket_text" id="ticket_text">
                                    {isAuthenticated ?
                                        <span>{name}</span> :
                                        <Link to="/registration">{t('Registration')}</Link>}
                                </div>
                            </div>
                            {
                                selectFriendsID && selectFriendsID.length > 0 ?
                                    selectFriendsID.map((item: any, key: number) =>
                                        <div className="ticket_user" key={key}>
                                            <div className="ticket_user_circle pointer">
                                                <img src={item.avatar ? item.avatar : ''}/>
                                            </div>
                                            <div className="ticket_text"> {item.name}</div>
                                            <div className="ticket_user_edit_list"
                                                 data-c={item.client_id || item.friends_id} onClick={delFriends}></div>
                                        </div>
                                    ) : ''
                            }
                            {
                                isAuthenticated && selectFriendsID.length < 3 ?
                                    <div className="ticket_user">
                                        <div className="ticket_user_circle pointer"
                                             onClick={() => {
                                                 handleModalFriends()
                                             }}>
                                            <div className="ticket_user_circle_text">{t('Add friend')}</div>
                                        </div>
                                    </div> : ''
                            }
                            {
                                !isAuthenticated ?
                                    <div className="ticket_user">
                                        <div className="ticket_user_circle pointer"
                                             onClick={() => {
                                                 props.handleModalLogin()
                                             }}>
                                            <div className="ticket_user_circle_text">{t('Add friend')}</div>
                                        </div>
                                    </div> : ''
                            }
                        </div>
                    </div>
                    <div className="ticket_footer">
                        <div className="ticket_footer_logo"></div>
                        {!isMobile ?
                            <div className="ticket_footer_col">
                                <div className="ticket_footer_col_title">{t('Number of tickets')}:</div>
                                <div className="ticket_footer_section">
                                    <div className="ticket_footer_col_btn" onClick={ticketColDown}>
                                        <span>-</span>
                                    </div>
                                    <div className="ticket_footer_col_number" id="ticketAmount">{ticketCol}</div>
                                    <div className="ticket_footer_col_btn" onClick={ticketColUp}>
                                        <span>+</span>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        <div className="ticket_footer_number">
                            <div className="ticket_footer_number_title">{t('Circulation')} №<span
                                id="circulation_item"></span>
                            </div>
                            <div className="ticket_footer_number_count" id="ticketNumbers">
                                <ScrollingCarousel>
                                    {ticketsNumberArray && ticketsNumberArray.map((item: any, key: number) =>
                                        <div className="ticket_footer_number_count_item" key={key}>
                                            <div className="ticket_footer_number_count_data">
                                                {item}
                                            </div>
                                        </div>
                                    )}
                                </ScrollingCarousel>
                            </div>
                        </div>
                        <div className="ticket_footer_date">
                            <div className="ticket_footer_date_title">{t('Draw date')}:</div>
                            <div className="ticket_footer_date_data"><span id="circulation_date">
                            {dateTicket}
                        </span></div>
                        </div>
                        {isMobile ?
                            <div className="ticket_footer_col">
                                <div className="ticket_footer_col_title">{t('Number of tickets')}:</div>
                                <div className="ticket_footer_section">
                                    <div className="ticket_footer_col_btn" onClick={ticketColDown}>
                                        <span>-</span>
                                    </div>
                                    <div className="ticket_footer_col_number" id="ticketAmount">{ticketCol}</div>
                                    <div className="ticket_footer_col_btn" onClick={ticketColUp}>
                                        <span>+</span>
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                </div>
                <div className="copyBlock" id="copyBlock"></div>
            </div>
            <div className="ticket_customizer">
                <div className="ticket_customizer_btn btn" onClick={handleModalBg}>
                    <div className="ticket_customizer_icon"></div>
                    <div className="ticket_customizer_title">{t('Change background')}</div>
                </div>
                <div className="ticket_customizer_btn btn" onClick={setInBasket}>
                    <div className="ticket_customizer_icon"></div>
                    <div className="ticket_customizer_title">{t('Add to Basket')}
                    </div>
                </div>
                <div className="ticket_customizer_btn btn">
                    <div className="ticket_customizer_icon"></div>
                    <Link className="ticket_customizer_title" to={"/basket"}>{t('Go to the payment')}</Link>
                </div>
            </div>
            <ModalAddBackground handleModal={handleModalBg} isActive={isActiveBg} changeBg={changeTicketBackground}/>
            <ModalAddFriends handleModal={handleModalFriends} isActive={isActiveFriends}
                             selectFriends={addFriend} handleModalLogin={props.handleModalLogin}/>
            <ModalSuccess isActive={isActiveModal} messages={messagesModal} isLocalize={true}
                          handleModal={handleModal}></ModalSuccess>
        </div>
    );
}

Ticket.defaultProps = {
    isCloseMouse: true
};
