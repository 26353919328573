import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {AuthContext} from "../../component/Auth/AuthContext";

function Basket() {
    const {t} = useTranslation();
    const {token, avatar, allTicketBasket, getBasketTicket} = useContext(AuthContext);
    const [allTicketBasketPrice, setAllTicketBasketPrice] = useState(0);
    const [iso, setIso] = useState('');

    useEffect(() => {
        setAllTicketBasketPrice(0)
        if (allTicketBasket && allTicketBasket.length > 0) {
            allTicketBasket.map((item: any) => {
                if (item.statusactive == "1") {
                    setAllTicketBasketPrice((allTicketBasketPrice) => allTicketBasketPrice + item.price)
                    setIso(item.iso)
                }
            })
        }
    }, [allTicketBasket])

    useEffect(() => {
        getBasketTicket()
    }, [])

    const payBasketTicket = () => {
        axios.post('https://massaccess.net:9060/billing/basketpay', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((data: any) => {
                getBasketTicket()
            })
            .catch(err => {
                getBasketTicket()
                console.error(err);
            });

    }
    const deleteTicket = (id: any) => {
        axios.post('https://massaccess.net:9060/public/basketticketdel', {"basketticket_id": id}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((data: any) => {
                getBasketTicket(token)
            })
            .catch(err => {
                console.error(err);
            });
    }
    return (
        <div className="content_block section">
            <div className="content card_section">
                <div className="card_block_title">{t('Ticket Basket')}</div>
                <div className="card_list_items" id="listTickets">
                    {allTicketBasket && allTicketBasket.length > 0 ? allTicketBasket.map((item: any, key: number) => {
                            return <div className="basketItemTicket" key={key}>
                                <div className="basketItemTicketSectionInfo"><span
                                    className="labelDrawTicket">{item.drawname}</span>
                                </div>
                                <div className="basketItemTicketInfo">
                                    <div className="basketItemTicketSection">
                                        <div className="basketItemTicketSectionDraw">
                                            <span className="labelBasketTicket">{t('Circulation')}: </span>
                                            <span className="valBasketTicket">{item.drawraffle_id}</span>
                                        </div>
                                        <div className="basketItemTicketSectionNumber">
                                            <span className="labelBasketTicket">{t('Number')}: </span>
                                            <span className="valBasketTicket">{item.ticketnumber}</span>
                                        </div>
                                    </div>
                                    <div className="basketItemTicketSection">
                                        <div className="basketItemTicketSectionPrice">
                                            <span className="labelBasketTicket">{t('Price')}: </span>
                                            <span className="valBasketTicket">{item.price} {item.iso}</span>
                                        </div>
                                        <div className="basketItemTicketSectionNumber">
                                            <span className="labelBasketTicket">{t('Status')}: </span>
                                            <span
                                                className="valBasketTicket">{item.statusactive == "1" ? t('Active') : t('Played')}</span>
                                        </div>
                                    </div>
                                    <div className="basketItemTicketSection">
                                        <div
                                            className={item.statusactive == "1" ? "basketItemTicketSectionBg" : "basketItemTicketSectionBg disabled"}>
                                            {item.basketticketbackground ? <img src={item.basketticketbackground}/> : ''}
                                            <div className="basketItemTicketSectionBgFr">
                                                <div className="basketItemTicketSectionBgFriends"><img src={avatar}/></div>
                                                {item.frends_id && item.frends_id.map((item: any) => {
                                                    return <div className="basketItemTicketSectionBgFriends"><img
                                                        src={item.avatarbackground}/></div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="basketItemTicketSection">
                                        <span className="delete" onClick={() => deleteTicket(item.id)}></span>
                                    </div>
                                </div>
                                {item.ticketcomment && item.ticketcomment !== '[]' ?
                                    <div className="basketItemTicketSectionInfo">
                                        <span>{t('Your wishes')}: </span>{item.ticketcomment}
                                    </div>
                                    : ''}
                            </div>
                        }
                    ) : t('Your basket is empty')}
                </div>
                <div className="card_btn_section">
                    <div className="btn btn_bg card_btn_section_item"
                         onClick={payBasketTicket}>{t('Proceed to checkout')}</div>
                    <div className="card_btn_section_value">
                        <div className="card_btn_section_value_title">{t('Total')}:</div>
                        <div className="card_btn_section_value_item" id="allPrice">{allTicketBasketPrice}{iso}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Basket;
