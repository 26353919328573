import React, { useContext, useEffect, useRef, useState } from "react"
import { Modal } from './Default/Modal'
import { useTranslation } from "react-i18next"
import { AuthContext } from "../Auth/AuthContext"
import Cropper, { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'

export interface Option {
    isActive: boolean,
    handleModal: any,
    changeBg: any
}

const ModalAddBackground = (props: Option) => {
    const { t } = useTranslation()
    const { ticketBackground } = useContext(AuthContext)
    const [backgroundList, setBackgroundList] = useState([{}])
    const [showCropper, setShowCropper] = useState(false)
    const [cropImage, setCropImage] = useState<string | undefined>()
    const cropperRef = useRef<ReactCropperElement>(null)

    const type = 'middle'

    const getDefaultBg = () => {
        setBackgroundList(ticketBackground)
    }

    const changeBgTicket = (image: string) => {
        props.changeBg(image)
        props.handleModal()
    }

    useEffect(() => {
        if (ticketBackground && ticketBackground.length > 0) {
            getDefaultBg()
        }
    }, [ticketBackground])

    const openChangeBgTicket = (fileItem: any) => {
        const file = fileItem.files[0]
        const reader = new FileReader()
        reader.onloadend = function () {
            setCropImage(reader.result as string)
            setShowCropper(true)
        }
        reader.readAsDataURL(file)
    }

    const handleCrop = () => {
        const cropper = cropperRef.current?.cropper
        const croppedImage = cropper?.getCroppedCanvas().toDataURL()
        if (croppedImage) {
            setBackgroundList(backgroundList => [...backgroundList, { image: croppedImage }])
            changeBgTicket(croppedImage)
        }
        setShowCropper(false)
    }

    return (
        <div>
            <Modal isOpen={props.isActive} onClose={props.handleModal} type={type}>
                <div className="modal_content_title">{t('Choose a ticket background')}</div>
                <div className="modal_content_bg" id="container_bg">
                    {backgroundList && backgroundList.map((item: any, key: number) =>
                        <div className="modal_content_bg_item" key={key} onClick={() => {
                            changeBgTicket(item.image)
                        }}>
                            <img src={item.image} alt="" />
                        </div>
                    )}
                </div>
                <div className="modal_item_input_load">
                    <input type="file" onChange={(e) => {
                        openChangeBgTicket(e.target)
                    }} id="elBgTicket" />
                    <div className="modal_item_input_load_title"><span>{t('Upload your background')}</span></div>
                </div>
            </Modal>
            {showCropper && (
                <Modal isOpen={showCropper} onClose={() => setShowCropper(false)} type={type}>
                    <div className="modal_content_title">{t('Crop your image')}</div>
                    <Cropper
                        src={cropImage}
                        style={{ height: 400, width: '100%' }}
                        // Cropper.js options
                        initialAspectRatio={16 / 9}
                        guides={false}
                        ref={cropperRef}
                        viewMode={1}
                        dragMode="move"
                    />
                    <button onClick={handleCrop} style={{ marginTop: 20}}><span>{t('Save')}</span></button>
                </Modal>
            )}
        </div>
    )
}

export default ModalAddBackground
