import React, {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import logo from '../../assets/style/img/svg/logo.svg'
import {AuthContext} from "../Auth/AuthContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export interface Option {
    triggerLeftMenu: any,
    handleModalLogin: any,
    handleModalProfile: any,
    triggerLanguage: any,
    isActiveProfile: Boolean,
    defaultLang: string
}

const Header = (props: Option) => {
    const nav = useNavigate();
    const {
        isAuthenticated,
        logout,
        drawCurrent,
        avatar,
        allTicketBasket,
        name,
        balance,
        currencyName,
        langsList
    } = useContext(AuthContext);
    const {t} = useTranslation();
    const [day, setDay] = useState('')
    const [daySt, setDaySt] = useState('')
    const [hours, setHours] = useState('')
    const [hoursSt, setHoursSt] = useState('')
    const [minutes, setMinutes] = useState('')
    const [minutesSt, setMinutesSt] = useState('')
    let ListLangUse = []
    let dateTicket= ''
    const logoutStart = () => {
        logout();
        props.handleModalProfile()
        nav('/')
    }
    useEffect(() => {
        if (drawCurrent && drawCurrent.length > 0) {
            let min = 1000000
            drawCurrent.map((item: any) => {
                let diff = Number(new Date(item.datestart)) - Number(new Date());
                if (min > diff) {
                    min = diff
                    dateTicket = item.datestart;
                }
            })
            updateDate(dateTicket);
            const interval = setInterval(() => {
                updateDate(dateTicket);
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [drawCurrent]);

    const updateDate = (dateData: any) => {
        if (dateData) {
            let declensionNum = (num: any, words: any) => {
                return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? num % 10 : 5]];
            }
            const diff = Number(new Date(dateData)) - Number(new Date());
            const daysFn = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
            const hoursFn = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
            const minutesFn = diff > 0 ? ((diff / 1000 / 60) % 60 > 1 ? Math.floor((diff / 1000 / 60) % 60 + 1) : 1) : 0;
            daysFn < 10 ? setDay('0' + daysFn) : setDay('' + daysFn);
            hoursFn < 10 ? setHours('0' + hoursFn) : setHours('' + hoursFn);
            minutesFn < 10 ? setMinutes('0' + minutesFn) : setMinutes('' + minutesFn);

            setDaySt(declensionNum(day, [t('day1'), t('day2'), t('day3')]))
            setHoursSt(declensionNum(hours, [t('oclock1'), t('oclock2'), t('oclock3')]))
            setMinutesSt(declensionNum(minutes, [t('min1'), t('min2'), t('min3')]))
        }
    }

    useEffect(()=>{
        if(langsList.length>0){
            ListLangUse = langsList
        }
    },[langsList])

    return (
        <div className="section header">
            <div className="content">
                <div className="header_burger" onClick={props.triggerLeftMenu}></div>
                <div className="header_logo">
                    <Link to="/" className="header_logo_href">
                        <img src={logo} alt="" className="logo_img"/>
                        <div className="logo_text">
                            {t('Play and win')}
                        </div>
                    </Link>
                </div>
                <div className="header_btn">
                    <div className="header_btn_panel">
                        <Link className="btn_item faq_img" to="/faq"></Link>
                        {isAuthenticated ? (
                            <Link className="btn_item wallet_img" to="/profile/wallet"></Link>
                        ) : ''}
                        {isAuthenticated ? (
                            <div className="btn_item profile_img" id="profile" onClick={props.handleModalProfile}>
                                <div className="avatar_profile" id="avatar_profile">
                                    <img src={avatar ? avatar : ''}/>
                                </div>
                            </div>
                        ) : (
                            <div className="btn_item profile_img" id="profile" onClick={props.handleModalLogin}>
                                <div className="avatar_profile" id="avatar_profile"></div>
                            </div>
                        )}
                        <div className={props.isActiveProfile ? "bg_main_menu open" : "bg_main_menu"}
                             onClick={props.handleModalProfile}></div>
                        <div className={props.isActiveProfile ? 'modal_profile_auth open' : 'modal_profile_auth'}
                             id="modal_profile_auth">
                            <div className="modal_profile_auth_profile">
                                <div className="modal_profile_auth_profile_img" id="profile_menu_item">
                                    <img src={avatar ? avatar : ''}/>
                                </div>
                                <div className="modal_profile_auth_profile_name"><span>{name}</span></div>
                                <div className="modal_profile_auth_profile_balance">
                                    <span>{balance} {currencyName}</span></div>
                            </div>
                            <div className="modal_profile_auth_btn"><Link to="/profile"
                                                                          onClick={props.handleModalProfile}>{t('Profile')}</Link>
                            </div>
                            <div className="modal_profile_auth_btn"><Link to="/profile/messages"
                                                                          onClick={props.handleModalProfile}>{t('Messages')}</Link>
                            </div>
                            <div className="modal_profile_auth_btn"><Link to="/profile/tickets"
                                                                          onClick={props.handleModalProfile}>{t('My tickets')}</Link>
                            </div>

                            <div className="modal_profile_auth_btn"><Link to="/profile/friends"
                                                                          onClick={props.handleModalProfile}>{t('My friends')}</Link>
                            </div>
                            <div className="modal_profile_auth_btn"><Link to="/profile/wallet"
                                                                          onClick={props.handleModalProfile}>{t('My finances')}</Link>
                            </div>
                            <div className="modal_profile_auth_btn" onClick={logoutStart}>{t('Exit')}</div>
                            <div className="modal_profile_close" onClick={props.handleModalProfile}>
                                <span>+</span>
                            </div>
                        </div>
                        <div className="btn_item language">
                            <select onChange={(e) => props.triggerLanguage(e.target)} value={props.defaultLang}>
                                {langsList && langsList.map((item:any)=>{
                                    return <option value={item.iso1.toLowerCase()}>{item.iso1}</option>
                                })}
                            </select>
                        </div>
                        <Link className="btn_item_main" to="/basket">
                            <div className="btn_item_main_pin">
                                <span className="btn_item_main_pin_bg"></span>
                                <span className="btn_item_main_pin_value"
                                      id="btn_item_main_pin_value">{allTicketBasket ? allTicketBasket.length : 0}</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="header_info">
                <div className="header_info_block">
                    <div className="info_title">{t('BEFORE THE DRAWING START')}</div>
                    <div className="info_blocks">
                        <div className="info_block">
                            <div className="info_block_title" id="info_block_day">{daySt}</div>
                            <div className="info_block_info">
                                <span id="info_block_day_time">{day}</span>
                            </div>
                        </div>
                        <div className="info_block">
                            <div className="info_block_title" id="info_block_oclock">{hoursSt}</div>
                            <div className="info_block_info">
                                <span id="info_block_oclock_time">{hours}</span>
                            </div>
                        </div>
                        <div className="info_block">
                            <div className="info_block_title" id="info_block_minut">{minutesSt}</div>
                            <div className="info_block_info">
                                <span id="info_block_minut_time">{minutes}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
