import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Select from "react-select";
import ModalSuccess from "../../component/Modal/ModalSuccess";
import {useNavigate} from "react-router-dom";

function Profile() {
    const {name, surname, phone, logout, dateBirth, clientSex, email, token} = useContext(AuthContext);
    const [nameUser, setNameUser] = useState('')
    const [surnameUser, setSurnameUser] = useState('')
    const [phoneUser, setPhoneUser] = useState('')
    const [validEmail, setValidEmailUser] = useState(true)
    const [dateBU, setDateBU] = useState('')
    const [clientSexUser, setClientSexUser] = useState()
    const [emailUser, setEmailUser] = useState('')
    const {t} = useTranslation();
    const nav = useNavigate();
    const [curPassword, setCurPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [validPassword, setValidPassword] = useState(true);
    const [validPasswordBtn, setValidPasswordBtn] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [isValidFormBtn, setIsValidFormBtn] = useState(false);
    const [newRepeatPassword, setNewRepeatPassword] = useState('');
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [isActiveModalDel, setIsActiveModalDel] = useState(false);
    const [messagesModal, setMessagesModal] = useState('');


    const optionsSex = [{label: t('Male'), value: 1}, {label: t('Female'), value: 2}]
    const sendOptionPassword = () => {
        if (validPasswordBtn) {
            if (newPassword == newRepeatPassword) {
                let data = {
                    "old_password": curPassword,
                    "password": newPassword
                }
                axios.post('https://massaccess.net:9060/public/passwordchange', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json',
                        Authorization: 'Bearer ' + token,
                    }
                })
                    .then((data) => {
                        setMessagesModal('Password updated')
                        setCurPassword('')
                        setNewPassword('')
                        setNewRepeatPassword('')
                        setValidPasswordBtn(false)
                        handleModal()
                    })
                    .catch(err => {
                        setMessagesModal(err)
                        handleModal()
                    });
            }
        }
    }
    const sendOptionUser = () => {
        if (isValidFormBtn) {
            changeForm()
            if (isValidForm) {
                let data = {
                    "name": nameUser,
                    "surname": surnameUser,
                    "phone": phoneUser,
                    "date_birth": dateBU,
                    "clientsex_id": clientSexUser,
                    "email": emailUser
                }
                axios.post('https://massaccess.net:9060/public/clientsupd', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json',
                        Authorization: 'Bearer ' + token,
                    }
                })
                    .then((data) => {
                        setMessagesModal('Profile settings saved')
                        setIsValidFormBtn(false)
                        handleModal()
                    })
                    .catch(err => {
                        setMessagesModal(err)
                        handleModal()
                    });
            }
        }
    }
    const handleModal = () => {
        setIsActiveModal(current => !current)
    }
    const handleModalDel = () => {
        setIsActiveModalDel(current => !current)
    }
    const checkEmailMask = (emailUser: string) => {
        if (/.+@.+\.[A-Za-z]+$/.test(emailUser)) {
            setEmailUser(emailUser);
            setValidEmailUser(true)
        } else {
            setEmailUser(emailUser);
            setValidEmailUser(false)
        }
    };
    const changeForm = () => {
        if (nameUser != '' && surnameUser != '' && phoneUser != '' && dateBU && dateBU != '' && clientSexUser != '' && emailUser != '' && validEmail) {
            setIsValidForm(true)
            setIsValidFormBtn(true)
        } else {
            setIsValidForm(false)
            setIsValidFormBtn(false)
        }
    }
    const changePass = () => {
        if ((newPassword == newRepeatPassword) && newPassword !== '' && newPassword.length >= 3) {
            setValidPassword(true)
            if (curPassword !== '' && curPassword.length >= 3) {
                setValidPasswordBtn(true)
            }
        } else {
            setValidPassword(false)
        }
    }
    const deleteAcc = () => {
        handleModalDel()
    }

    const delOk = () => {
        axios.post('https://massaccess.net:9060/public/accountdelete', {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((data) => {
                handleModalDel();
                logout();
                nav('/');
            })
            .catch(err => {
                console.log('Error 4')
            });
    }

    useEffect(() => {
        if (name) {
            setNameUser(name)
            setSurnameUser(surname)
            setPhoneUser(phone)
            setDateBU(dateBirth)
            setClientSexUser(clientSex)
            setEmailUser(email)
        }
    }, [name, clientSex])
    useEffect(() => {
        if (dateBU) {
            changeForm()
        }
    }, [dateBU, emailUser, nameUser, surnameUser, phoneUser])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <div className="content_panel_max_card">
            <div className="title_sup_info"><span>{t('My profile')}</span></div>
            <div className="content_panel_max_card_section">
                <div className="input_block">
                    <div className="input_block_label">{t('Name')}</div>
                    <input type="text" placeholder={t('Name')} value={nameUser}
                           onChange={(e: any) => {
                               setNameUser(e.target.value);
                               changeForm()
                           }}
                    /></div>
                <div className="input_block">
                    <div className="input_block_label">{t('Surname')}</div>
                    <input type="text" placeholder={t('Surname')} value={surnameUser}
                           onChange={(e: any) => {
                               setSurnameUser(e.target.value);
                               changeForm()
                           }}
                    /></div>
                <div className="input_block">
                    <div className="input_block_label">{t('Phone')}</div>
                    <input type="text" placeholder={t('Phone')} value={phoneUser}
                           onChange={(e: any) => {
                               setPhoneUser(e.target.value);
                               changeForm()
                           }}/></div>
                <div className="input_block">
                    <div className="input_block_label">{t('Date of Birth')}</div>
                    <input type="date" placeholder={t('Date of Birth')} value={dateBU}
                           onChange={(e: any) => {
                               setDateBU(e.target.value)
                               changeForm()
                           }}/></div>
                <div className="select_block">
                    <div className="input_block_label">{t('Sex')}</div>
                    <Select name="itemCurrency"
                            value={
                                optionsSex.filter((option: any) =>
                                    option.value === clientSexUser
                                )
                            }
                            onChange={(e: any) => {
                                setClientSexUser(e.value);
                                changeForm()
                            }}

                            options={optionsSex}
                            classNamePrefix={'profile'}
                            className={'profileSelect'}/>
                </div>
                <div className={validEmail ? "input_block" : "input_block not_valid"}>
                    <div className="input_block_label">{t('Email')}</div>
                    <input type="text" placeholder="Email" value={emailUser} onChange={(e: any) => {
                        setEmailUser(e.target.value)
                        checkEmailMask(e.target.value);
                        changeForm()
                    }}/>
                </div>
                <div className="input_block_btn">
                    <div className={isValidFormBtn ? "btn" : "btn disabled"} onClick={sendOptionUser}>
                        <span>{t('Save changes')}</span></div>
                </div>
            </div>
            <div className="title_sup_info"><span>{t('Change password')}</span></div>

            <div className="content_panel_max_card_section">
                <div className="input_block width-33">
                    <div className="input_block_label">{t('New Password')}</div>
                    <input value={newPassword} name="newPassword" type="password"
                           onChange={e => setNewPassword(e.target.value)}/>
                </div>
                <div className={validPassword ? "input_block width-33" : "input_block width-33 not_valid"}>
                    <div className="input_block_label">{t('Repeat New Password')}</div>
                    <input value={newRepeatPassword} onBlur={changePass} name="newRepeatPassword" type="password"
                           onChange={e => {
                               setNewRepeatPassword(e.target.value)
                           }}/>
                </div>
                <div className="input_block width-33">
                    <div className="input_block_label">{t('Current Password')}</div>
                    <input value={curPassword} name="firstName" type="password" onBlur={changePass} onChange={e => {
                        setCurPassword(e.target.value);
                        changePass()
                    }}/>
                </div>
                <div className="input_block_btn">
                    <div className={validPasswordBtn ? "btn" : "btn disabled"} onClick={sendOptionPassword}>
                        <span>{t('Change password')}</span></div>
                </div>
            </div>
            <div className="input_block_btn">
                <div className="btn del_acc" onClick={deleteAcc}>
                    <span>{t('Delete account')}</span></div>
            </div>
            <ModalSuccess isActive={isActiveModal} messages={messagesModal} isLocalize={true}
                          handleModal={handleModal}></ModalSuccess>
            <ModalSuccess isActive={isActiveModalDel} messages={'Are you sure you want to delete your account?'}
                          isLocalize={true} callback={delOk}
                          handleModal={handleModalDel}></ModalSuccess>
        </div>
    );
}

export default Profile;
