import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";

export interface Option {
    defaultLang: string
}

function Drawraffleget(props: Option) {
    const {t} = useTranslation();
    const [listTicketArray, setListTicketArray] = useState([])
    const getCookies = (name: string) => {
        let res = '';
        document.cookie.split(';').forEach(c => {
            if (c.trim().startsWith(name))
                res += decodeURIComponent(c) + '';
        });
        return res;
    }
    const drawraffleget = () => {
        axios.post('https://massaccess.net:9060/public/drawraffleget', {p_page: 1}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + getCookies('sl-auth-token').split('=')[1],
            }
        })
            .then((data) => {
                setListTicketArray(data.data.data)
                document.querySelector('#root')!.scrollIntoView();
            })
            .catch(err => {
                console.error('not update avatar')
            });
    }

    useEffect(() => {
        drawraffleget()
    }, [])
    return (
        <div className="content_block section">
            <div className="content card_section drawraffleget_section">
                <div className="card_block_title center">{t('Draw results')}</div>
                <div className="drawraffleget_content">
                    {listTicketArray && listTicketArray.map((item: any) =>
                        <div className="drawraffleget_line">
                            <div className="drawraffleget_line_item left">
                                {item.locallang && item.locallang.map((item:any)=> {
                                    if (item.iso == props.defaultLang) {
                                        return <div className="drawraffleget_line_item_header">{item.text_translation}</div>
                                    }
                                })}
                                <div
                                    className="drawraffleget_line_item_date">{new Date(item.drawdatetime).toLocaleString()}</div>
                            </div>
                            <div className="drawraffleget_line_item">
                                <div className="drawraffleget_line_item_label">{t('Winning number')}</div>
                                <div className="drawraffleget_line_item_value">{item.wincombnumbers}</div>
                            </div>
                            <div className="drawraffleget_line_item right">
                                <div className="drawraffleget_line_item_label">{t('Winning tickets')}</div>
                                <div className="drawraffleget_line_item_value_ticket">{item.count_buy_ticket}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Drawraffleget;
