import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {AuthContext} from "../../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";
import ItemList from "./ItemList/ItemTicket";
import Pagination from "../../../component/Pagination/Pagination";

function WaitTicket() {
    const {token} = useContext(AuthContext);
    const [allTicket, setAllTicket] = useState([]);
    const {t} = useTranslation();
    const [totalItems, setTotalItems] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);
    const itemsPerPage = 100;
    let pageSelect = 1;
    const paginate = (pageNumber: any) => {
        pageSelect = pageNumber
        getTicket()
    };
    const getTicket = () => {
        axios.post('https://massaccess.net:9060/public/ticketwait', {page: pageSelect}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((data) => {
                setTotalItems(data.data.meta.rowTotal);
                setPageTotal(data.data.meta.pageTotal);
                setAllTicket(data.data.data)
            })
            .catch(err => {
                console.error(err);
            });
    }

    useEffect(() => {
        if (token && token.length > 0) {
            getTicket()
        }
    }, [token]);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="items_tickets">
            {allTicket ? <ItemList list={allTicket}/> : <div className="notYet"><span>{t('Not yet')}</span></div>}
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                totalPage={pageTotal}
                paginate={paginate}
            />
        </div>
    );
}

export default WaitTicket;
