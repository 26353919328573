import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { AuthContext } from "./component/Auth/AuthContext"

import './App.scss'
import Header from "./component/Header/Header"
import Footer from "./component/Footer/Footer"
import Basket from "./page/Basket/Basket"
import Edition from "./page/Edition/Edition"
import FAQ from "./page/Faq/Faq"
import Friends from "./page/Friends/Friends"
import Messages from "./page/Messages/Messages"
import Drawraffleget from "./page/Drawraffleget/Drawraffleget"
import Main from "./page/Main/Main"
import Profile from "./page/Profile/Profile"
import Registration from "./page/Registration/Registration"
import Forgot from "./page/Forgot/Forgot"
import Confirm from "./page/Confirm/Confirm"
import Tickets from "./page/Tickets/Tickets"
import Wallet from "./page/Wallet/Wallet"
import LeftMenu from "./component/LeftMenu/LeftMenu"
import ModalLogin from "./component/Modal/ModalLogin"
import ModalLoginError from "./component/Modal/ModalLoginError"
import ModalForgot from "./component/Modal/ModalForgot"
import ModalForgotStatus from "./component/Modal/ModalForgotStatus"
import ModalGetCurrency from "./component/Modal/ModalGetCurrency"
import Cabinet from "./page/Cabinet/Cabinet"
import AllTicket from "./page/Cabinet/section/AllTicket"
import WinTicket from "./page/Cabinet/section/WinTicket"
import WaitTicket from "./page/Cabinet/section/WaitTicket"
import DonatedTicket from "./page/Cabinet/section/DonatedTicket"
import NotWorkRegion from "./page/NotWorkRegion/NotWorkRegion"
import axios from "axios"

function App() {
    const { i18n } = useTranslation()
    const [isActiveLeft, setIsActiveLeft] = useState(false)
    const [isActiveLogin, setIsActiveLogin] = useState(false)
    const [isActiveLoginError, setIsActiveLoginError] = useState(false)
    const [isActiveProfile, setIsActiveProfile] = useState(false)
    const [isActiveForgot, setIsActiveForgot] = useState(false)
    const [isActiveForgotStatus, setIsActiveForgotStatus] = useState(false)
    const [isForgotStatusError, setIsForgotStatusError] = useState('')
    const [isActiveGC, setIsActiveGC] = useState(false)
    const [forgotMsg, setForgotMsg] = useState('')
    const [defaultLang, setDefaultLang] = useState('en')

    const {
        isAuthenticated,
        currenciesId,
        statusLogin,
        isGoMain,
        setIsGoMain,
        langUser,
        token
    } = useContext(AuthContext)


    const getCookies = (name: string) => {
        let res = ''
        document.cookie.split(';').forEach(c => {
            if (c.trim().startsWith(name))
                res += decodeURIComponent(c) + ''
        })
        return res
    }
    const BlockSite = getCookies('not-work').split('=')[1]
    const setCookie = (name: string, value: any, time: any, path?: any, domain?: any, secure?: any) => {
        const dateS = new Date()
        dateS.setTime(dateS.getTime() + (time * 1000))
        document.cookie = name + '=' + value +
            '; path=/' +
            '; expires=' + dateS.toUTCString() +
            '; domain=' + ((domain) ? domain : '') +
            '; secure=' + ((secure) ? secure : '')
    }

    const handleLeftMenu = () => {
        setIsActiveLeft(current => !current)
        const hash = window.location!.hash.split('#')[1] || ''
        if (hash != '' && document.querySelector('#' + hash)) {
            const y = document.getElementById(hash)!.getBoundingClientRect().top + window.pageYOffset - 90
            window.scrollTo({ top: y, behavior: 'smooth' })
        }
    }
    const handleModalLogin = () => {
        setIsActiveLogin(current => !current)
    }
    const handleModalLoginError = () => {
        setIsActiveLoginError(current => !current)
    }
    const handleModalProfile = () => {
        setIsActiveProfile(current => !current)
    }
    const handleModalGetCurrency = () => {
        setIsActiveGC(current => !current)
    }
    const handleModalForgot = () => {
        setIsActiveForgot(current => !current)
    }
    const handleModalForgotStatus = (status: string, message: any) => {
        setForgotMsg(message)
        setIsForgotStatusError(status)
        setIsActiveForgotStatus(current => !current)
    }

    interface Option {
        value: string,
    }

    useEffect(() => {
        if (currenciesId == null) {
            handleModalGetCurrency()
        }
    }, [currenciesId])
    useEffect(() => {
        if (langUser !== '') {
            triggerLanguage({ value: langUser })
        }
    }, [langUser])

    useEffect(() => {
        if (statusLogin && statusLogin.response && statusLogin.response.status == 400) {
            handleModalLoginError()
        }
    }, [statusLogin])
    useEffect(() => {
        if (isGoMain) {
            window.location.pathname = '/'
            setIsGoMain(false)
        }
    }, [isGoMain])

    const triggerLanguage = (props: Option) => {
        setCookie('salut_lang', props.value, 86400)
        setDefaultLang(props.value)
        i18n.changeLanguage(props.value)
        if (isAuthenticated) {
            axios.post('https://massaccess.net:9060/public/clientslangupd', { "langs": props.value }, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    Authorization: 'Bearer ' + token,
                }
            })
                .then((data) => {
                    console.log(data)
                    
                })
                .catch(err => {
                    console.log('Error 6')
                })

        }
    }

    useEffect(() => {
        const lang = getCookies('salut_lang').split('=')[1]
        if (lang && lang !== '') {
            triggerLanguage({ value: lang })
        } else {
            setDefaultLang('en')
            setCookie('salut_lang', 'en', 86400)
        }
    }, [])


    return (
        <Router>
            <Routes>
                <Route path="/"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> : <Main handleModalLogin={handleModalLogin} />}
                />
                <Route path="/faq" element={BlockSite === 'yes' ? <NotWorkRegion /> : <FAQ />} />
                <Route path="/forgot_password/:guid" element={BlockSite === 'yes' ? <NotWorkRegion /> : <Forgot />} />
                <Route path="/public/confirm" element={BlockSite === 'yes' ? <NotWorkRegion /> : <Confirm />} />
                <Route path="/drawraffleget" element={BlockSite === 'yes' ? <NotWorkRegion /> : <Drawraffleget defaultLang={defaultLang} />} />
                <Route path="/registration" element={BlockSite === 'yes' ? <NotWorkRegion /> : <Registration />} />
                <Route path="/basket" element={BlockSite === 'yes' ? <NotWorkRegion /> : <Basket />} />
                <Route path="/edition" element={BlockSite === 'yes' ? <NotWorkRegion /> : <Edition />} />
                <Route path="/profile" element={BlockSite === 'yes' ? <NotWorkRegion /> :
                    <Cabinet active='profile' isEdit={true}><Profile /></Cabinet>} />
                <Route path="/profile/friends" element={BlockSite === 'yes' ? <NotWorkRegion /> :
                    <Cabinet active='friends'><Friends /></Cabinet>} />
                <Route path="/profile/messages" element={BlockSite === 'yes' ? <NotWorkRegion /> :
                    <Cabinet active='messages'><Messages /></Cabinet>} />
                <Route path="/profile/tickets"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> :
                        <Cabinet active='tickets'><Tickets isType={'all'}><AllTicket /></Tickets></Cabinet>} />
                <Route path="/profile/tickets/win"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> :
                        <Cabinet active='tickets'><Tickets isType={'win'}><WinTicket /></Tickets></Cabinet>} />
                <Route path="/profile/tickets/waiting"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> : <Cabinet active='tickets'><Tickets
                        isType={'waiting'}><WaitTicket /></Tickets></Cabinet>} />
                <Route path="/profile/tickets/donated"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> : <Cabinet active='tickets'><Tickets
                        isType={'donated'}><DonatedTicket /></Tickets></Cabinet>} />
                <Route path="/profile/tickets/gave"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> :
                        <Cabinet active='tickets'><Tickets isType={'gave'}><AllTicket /></Tickets></Cabinet>} />
                <Route path="/profile/tickets/delayed"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> : <Cabinet active='tickets'><Tickets
                        isType={'delayed'}><AllTicket /></Tickets></Cabinet>} />
                <Route path="/profile/wallet"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> : <Cabinet active='wallet'><Wallet /></Cabinet>} />
                <Route path="/notwork" element={BlockSite === 'yes' ? <NotWorkRegion /> : <NotWorkRegion />} />
                <Route path="*"
                    element={BlockSite === 'yes' ? <NotWorkRegion /> : <Main handleModalLogin={handleModalLogin} />} />
            </Routes>
            {/*Дополнительные компоненты*/}
            {BlockSite !== 'yes' ?
                <Header triggerLeftMenu={handleLeftMenu} triggerLanguage={triggerLanguage}
                    handleModalProfile={handleModalProfile} defaultLang={defaultLang}
                    handleModalLogin={handleModalLogin} isActiveProfile={isActiveProfile} />
                : ''}
            {BlockSite !== 'yes' ?
                <Footer />
                : ''}
            <LeftMenu triggerLeftMenu={handleLeftMenu} isActiveLeftMenu={isActiveLeft}
                handleModalLogin={handleModalLogin} />
            {/*Модальные окна*/}

            <ModalLogin handleModal={handleModalLogin} isActive={isActiveLogin} handleModalForgot={handleModalForgot} />
            <ModalLoginError handleModal={handleModalLoginError} isActive={isActiveLoginError} />
            <ModalForgot handleModal={handleModalForgot} isActive={isActiveForgot}
                handleModalForgotStatus={handleModalForgotStatus} />
            <ModalForgotStatus handleModal={handleModalForgotStatus} forgotMsg={forgotMsg}
                isForgotStatusError={isForgotStatusError} isActive={isActiveForgotStatus} />
            <ModalGetCurrency handleModal={handleModalGetCurrency} isActive={isActiveGC} />
        </Router>
    )
}

export default App
