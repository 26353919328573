import React, { useContext, useEffect, useRef, useState } from "react"
import { Modal } from './Default/Modal'
import { useTranslation } from "react-i18next"
import { AuthContext } from "../Auth/AuthContext"
import axios from "axios"
import Cropper, { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'

export interface Option {
  isActive: boolean,
  handleModal: any,
  changeBg: any
}

const ModalChangeAvatar = (props: Option) => {
  const { t } = useTranslation()
  const { avatar, token, getClientSingle } = useContext(AuthContext)
  const [showCropper, setShowCropper] = useState(false)
  const [cropImage, setCropImage] = useState<string | undefined>()
  const cropperRef = useRef<ReactCropperElement>(null)

  const [newAvatar, setNewAvatar] = useState('')
  const type = ''

  const openChangeAvatar = (fileItem: any) => {
    const file = fileItem.files[0]
    const reader = new FileReader()
    reader.onloadend = function () {
      setCropImage(reader.result as string)
      setShowCropper(true)
    }
    reader.readAsDataURL(file)
  }

  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper
    const croppedImage = cropper?.getCroppedCanvas().toDataURL()
    if (croppedImage) {
      setNewAvatar(croppedImage)
    }
    setShowCropper(false)
  }

  const saveNewAvatar = () => {
    axios.post('https://massaccess.net:9060/public/avatarupd', { image: newAvatar }, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        Authorization: 'Bearer ' + token,
      }
    })
      .then((data) => {
        props.handleModal()
        getClientSingle(token)
      })
      .catch(err => {
        console.error('not update avatar')
      })
  }

  useEffect(() => {
    if (avatar != '') {
      setNewAvatar(avatar)
    }
  }, [avatar])

  return (
    <div>
      <Modal isOpen={props.isActive} onClose={props.handleModal} type={type}>
        <div className="modal_content_title">{t('Choose a new avatar')}</div>
        <div className="modal_items_photo" id="previewAvatar">
          <img src={newAvatar} alt="" />
        </div>
        <div className="modal_item_add_file">
          <input type="file" onChange={(e) => {
            openChangeAvatar(e.target)
          }} />
          <div className="modal_content_bg_btn btn"><span>{t('Upload your avatar')}</span></div>
        </div>
        <div className="modal_action">
          <div className="modal_content_bg_btn btn" onClick={saveNewAvatar}><span>{t('Save')}</span></div>
        </div>
      </Modal>
      {showCropper && (
        <Modal isOpen={showCropper} onClose={() => setShowCropper(false)} type={type}>
          <div className="modal_content_title">{t('Crop your image')}</div>
          <Cropper
            src={cropImage}
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={1}
            guides={false}
            ref={cropperRef}
            viewMode={1}
            dragMode="move"
          />
          <button onClick={handleCrop} style={{ marginTop: 20}}>{t('Save')}</button>
        </Modal>
      )}
    </div>
  )
}

export default ModalChangeAvatar
