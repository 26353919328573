import React, {useState} from 'react';

interface Option {
    itemsPerPage: any
    totalItems: any
    totalPage: any
    paginate: any
}

const Pagination = (props: Option) => {
    const pageNumbers = [];
    const [currentPage, setCurrentPage] = useState(1);

    for (let i = 1; i <= Math.ceil(props.totalItems / props.itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleClick = (number: any) => {
        setCurrentPage(number);
        props.paginate(number);
    };

    return (
        <nav>
            <div className="pagination">
                {currentPage > 1 &&
                    <div className='page-item'>
                        <div onClick={() => handleClick(1)}
                             className={`page-link ${currentPage === 1 ? 'active' : ''}`}
                        >
                            1
                        </div>
                    </div>
                }
                {currentPage > 2 &&
                    <div className='page-item'>
                        <div onClick={() => handleClick(currentPage - 1)}
                             className={`page-link ${currentPage === currentPage - 1 ? 'active' : ''}`}
                        >
                            &#60;
                        </div>
                    </div>}
                {pageNumbers.map((number: any) => (
                    (number === (currentPage - 1) && number !== 2 && number !== 1) || number === currentPage || (number === (currentPage + 1) && number != props.totalPage && number != props.totalPage - 1) ?
                        <div key={number} className={currentPage === number ? 'page-item active' : 'page-item'}>
                            <div
                                onClick={() => handleClick(number)}
                                className="page-link"
                            >
                                {number}
                            </div>
                        </div> : ''
                ))}
                {currentPage < props.totalPage && currentPage + 1 !== props.totalPage &&
                    <div className='page-item'>
                        <div onClick={() => handleClick(currentPage + 1)}
                             className={`page-link ${currentPage === currentPage + 1 ? 'active' : ''}`}
                        >
                            &#62;
                        </div>
                    </div>
                }
                {currentPage < props.totalPage &&
                    <div className='page-item'>
                        <div onClick={() => handleClick(props.totalPage)}
                             className={`page-link ${currentPage === props.totalPage ? 'active' : ''}`}
                        >
                            {props.totalPage}
                        </div>
                    </div>}
            </div>
        </nav>
    );
};

export default Pagination;
