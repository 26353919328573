import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface OptionModal {
    isType: string,
    children: any,
}

function Tickets(props: OptionModal) {
    const {t} = useTranslation();
    return (
        <div className="content_panel_max_card">
            <div className="content_panel_max_card_section">
                <Link to="/profile/tickets"
                      className={"btn " + (props.isType === 'all' ? 'btn_bg' : '')}><span>{t('All tickets')}</span></Link>
                <Link to="/profile/tickets/win"
                      className={"btn " + (props.isType === 'win' ? 'btn_bg' : '')}><span>{t('Winning')}</span></Link>
                <Link to="/profile/tickets/waiting"
                      className={"btn " + (props.isType === 'waiting' ? 'btn_bg' : '')}><span>{t('Are waiting draw')}</span></Link>
                <Link to="/profile/tickets/donated"
                      className={"btn " + (props.isType === 'donated' ? 'btn_bg' : '')}><span>{t('To me gave')}</span></Link>
            </div>
            <div className="title_sup_info"><span>{t('Tickets')}</span></div>
            {props.children}
        </div>
    );
}

export default Tickets;
