import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Ticket} from './Component/Ticket/Ticket';
import {AuthContext} from "../../component/Auth/AuthContext";
import FriendsRandom from "./Component/FriendsRandom/FriendsRandom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SelectWithSearch from './Component/SelectWithSearch/SelectWithSearch';
import ModalSuccess from "../../component/Modal/ModalSuccess";
import FriendsMyCircle from "./Component/FriendsMyCircle/FriendsMyCircle";


export interface Option {
    handleModalLogin?: any
}

export interface buyTicket {
    ticketbackground: any,
    avatar: any
}

export interface ColourOption {
    readonly avatar: string;
    readonly client_id: number;
    readonly name: string;
    readonly surname: string;
}

function Main(props: Option) {

    const {t} = useTranslation();
    const [listRandomTicket, setListRandomTicket] = useState([]);
    const [selectFriends, setSelectFriends] = useState();
    const [isActiveModal, setIsActiveModal] = useState(false);
    const {ticketCurrentBuy, isAuthenticated, getMyFriend} = useContext(AuthContext);
    const [messagesModal, setMessagesModal] = useState('');

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 8000, min: 1200},
            items: 7
        },
        desktop: {
            breakpoint: {max: 1200, min: 769},
            items: 4
        },
        tablet: {
            breakpoint: {max: 769, min: 460},
            items: 3
        },
        mobile: {
            breakpoint: {max: 460, min: 0},
            items: 2
        }
    };
    const responsive_fr = {
        superLargeDesktop: {
            breakpoint: {max: 18000, min: 1200},
            items: 5
        },
        desktop: {
            breakpoint: {max: 1200, min: 700},
            items: 3
        },
        tablet: {
            breakpoint: {max: 700, min: 460},
            items: 2
        },
        mobile: {
            breakpoint: {max: 460, min: 0},
            items: 2
        }
    }
    const responsive_fr_all = {
        superLargeDesktop: {
            breakpoint: {max: 18000, min: 1200},
            items: 5
        },
        desktop: {
            breakpoint: {max: 1200, min: 700},
            items: 3
        },
        tablet: {
            breakpoint: {max: 700, min: 460},
            items: 2
        },
        mobile: {
            breakpoint: {max: 460, min: 0},
            items: 3
        }
    }
    // @ts-ignore
    const ButtonGroup = ({next, previous, goToSlide, ...rest}) => {
        const {carouselState: {currentSlide}} = rest;
        return (
            <div className="carousel-button-group">
                <div onClick={() => previous()}></div>
                <div onClick={() => next(5)}></div>
            </div>
        );
    };

    useEffect(() => {
        const hash = window.location!.hash.split('#')[1] || '';
        if (hash != '' && document.querySelector('#' + hash)) {
            const y = document.getElementById(hash)!.getBoundingClientRect().top + window.pageYOffset - 90;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }, [])
    useEffect(() => {
        if (ticketCurrentBuy && ticketCurrentBuy.length > 0) {
            let list = ticketCurrentBuy.map((ticket: buyTicket, key: number) =>
                <div className="ticket_slider_item" key={key}>
                    {ticket.ticketbackground ? <img src={ticket.ticketbackground}/> :
                        <span className="ticket_slider_item_not_bg"></span>}
                    <div className="avatarBgTicketBay"><img src={ticket.avatar}/></div>
                </div>
            )
            setListRandomTicket(list)
        }
    }, [ticketCurrentBuy]);

    const handleModal = () => {
        setIsActiveModal(current => !current)
    }
    const goToTicket = () => {
        if (document.querySelector('#buy')) {
            const y = document.getElementById('buy')!.getBoundingClientRect().top + window.pageYOffset - 90;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
    const addFriends = (data: any) => {
        setMessagesModal('User added as friend and ticket')
        handleModal()
        getMyFriend()
    }
    return (
        <div>
            <div className="section main">
                <div className="content">
                    <div className="title_main">
                        {t('mainText')}
                    </div>
                    <div className="btn" onClick={goToTicket}><span>{t('welcome')}</span></div>
                </div>
            </div>
            <div className="section how" id="how-it-works">
                <div className="content">
                    <div className="title">{t('howItWorks')}</div>
                    <div className="step">
                        <div className="item_step">
                            <div className="item_num">01</div>
                            <div className="item_title">{t('Add friends to ticket')}</div>
                            <div className="item_description">{t('Attach your favorite photo with wishes')}</div>
                        </div>
                        <div className="item_step">
                            <div className="item_num">02</div>
                            <div className="item_title">{t('Wait for the draws')}</div>
                            <div className="item_description">
                                <span>1. {t('Every Sunday at 20:00')}</span>
                                <span>2. {t('Once every six months Super Prizes')}</span>
                            </div>
                        </div>
                        <div className="item_step">
                            <div className="item_num">03</div>
                            <div className="item_title">{t('Played out')}</div>
                            <div className="item_description">
                                <span>{t('Money, apartments, houses')}</span>
                                <span>{t('We all win!')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section users">
                <div className="content">
                    <div className="title">{t('Lottery participants')}</div>
                    <div className="add_friend">
                        <div className="input_section">
                            <SelectWithSearch select={addFriends} className="inputNameFriend"
                                              handleModalLogin={props.handleModalLogin}/>
                            <div className="input_section_item">
                                <div className="input_item_icon"></div>
                                <div className="input_item_filter"></div>
                            </div>
                        </div>
                        {/*<div className="btn_add">
                            <div className="btn"><span>{t('Invite')}</span></div>
                        </div>*/}
                    </div>
                    <div className="all_user slider">
                        <div className="all_user_section slides" id="all_random_user_section_rf">
                            <FriendsRandom handleModalLogin={props.handleModalLogin}
                                           responsive={responsive_fr}></FriendsRandom>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section ticket" id="buy">
                <div className="content">
                    <div className="title">{t('Make a beautiful lottery')}<br/>
                        {t('ticket with a friend')}
                    </div>
                    <div className="sup_title">
                        <p>1. {t('Add friends')}</p>
                        <p>2. {t('Attach your photo or background')}</p>
                        <p>3. {t('Write wishes, send to friends and wait for prizes')}</p>
                    </div>
                    {isAuthenticated ?
                        <div className="circle_slider">
                            <FriendsMyCircle responsive={responsive_fr_all} select={(data: any) => {
                                setSelectFriends(data)
                            }}></FriendsMyCircle>
                        </div>
                        : ""}
                    <Ticket handleModalLogin={props.handleModalLogin}
                            selectFriendsTopSearch={selectFriends}/>
                    <div className="pay_ticket">
                        <div className="pay_ticket_title">{t('Tickets sold')}</div>
                        <div className="pay_ticket_slider">
                            <div className="pay_ticket_slider_items" id="ticket_buy">
                                <Carousel swipeable={true}
                                          responsive={responsive}
                                          infinite={true}
                                          draggable={false}
                                          removeArrowOnDeviceType={["mobile", "tablet"]}
                                          arrows={false}
                                          renderButtonGroupOutside={true}
                                          minimumTouchDrag={10}
                                    // @ts-ignore
                                          customButtonGroup={<ButtonGroup/>}
                                >
                                    {listRandomTicket.map((item, key: number) =>
                                        <div className="itemCarousel" key={key}>{item}</div>)
                                    }
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalSuccess isActive={isActiveModal} messages={messagesModal} isLocalize={true}
                          handleModal={handleModal}></ModalSuccess>
        </div>
    )
}


export default Main;
