import React, {useContext} from "react"
import {Modal} from './Default/Modal'
import {AuthContext} from "../Auth/AuthContext"
import {useTranslation} from "react-i18next"

export interface Option {
    isActive: boolean,
    handleModal: any,
}

const ModalLoginError = (props: Option) => {
    const {t} = useTranslation()
    const {statusLogin} = useContext(AuthContext)
    return (
        <Modal isOpen={props.isActive} onClose={props.handleModal}>
            <div className="modal_profile_title">{t('Error')}</div>
            {statusLogin && statusLogin.response && statusLogin.response.status == 400 ?
                <div className="modal_profile_description">
                    {statusLogin.response.data.message === "cl-error-mailpassword" ?
                        t('Incorrect email or password!') : statusLogin.response.data.message
                    }
                </div>
                :
                ''
            }
        </Modal>
    )
}

export default ModalLoginError
