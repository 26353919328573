import React, {useContext} from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import './i18n'

import './assets/style/reset.css'
import './assets/style/main.css'
import './assets/style/adaptive_tab.css'
import './assets/style/adaptive_mobile.css'

import {AuthProvider} from './component/Auth/AuthContext'
import axios from "axios"


const setCookie = (name: string, value: string, time: number, path?: any, domain?: any, secure?: any) => {
    const date = new Date()
    date.setTime(date.getTime() + (time * 1000))
    document.cookie = name + '=' + value +
        '; path=/' +
        '; expires=' + date.toUTCString() +
        '; domain=' + ((domain) ? domain : '') +
        '; secure=' + ((secure) ? secure : '')
}
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)


const inti = () => {
    root.render(
        <div className="container">
            <React.StrictMode>
                <AuthProvider>
                    <App/>
                </AuthProvider>
            </React.StrictMode>
        </div>
    )
}


axios.post('https://massaccess.net:9060/content/auth', {}, {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
})
    .then((data) => {
        setCookie('sl-auth-token', data.data.data.token, 86400)
        axios.get('https://massaccess.net:9060/public/blocksite', {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + data.data.data.token,

            }
        })
            .then((data) => {
                if (data.data.status !== -1) {
                    setCookie('not-work', 'no', 86400)
                } else {
                    setCookie('not-work', 'yes', 86400)
                }
                inti()
            })
            .catch(err => {
                console.log('Error 3')
            })
    })
