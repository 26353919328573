import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";
import axios from "axios";

interface OptionRandomFriend {
    avatar: string
    name: string
    email: string
    friends_id: number
}

function Friends() {
    const {token} = useContext(AuthContext);
    const {t} = useTranslation();
    const [listFriends, setListFriends] = useState([]);
    const getMyFriend = () => {
        axios.post('https://massaccess.net:9060/public/clientsfriendsget', {p_page: 1}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((data) => {
                if (data && data.data) {
                    setListFriends(data.data.data.map((isItem: OptionRandomFriend) =>
                        <div className="item_user_friend width-50">
                            <div className="img_user">
                                <img src={isItem.avatar}/>
                            </div>
                            <div className="title_user">
                                {isItem.name} <span className="isItemEmail">{isItem.email}</span>
                            </div>
                            <div className="del_friend_user" onClick={() => delFriends(isItem.friends_id)}></div>
                        </div>
                    ))
                }
            })
            .catch(err => {
                console.error('not update avatar')
            });
    }
    const delFriends = (id: number) => {
        axios.post('https://massaccess.net:9060/public/clientsfriendsdel', {clientsfriends_id: id}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
            .then((data) => {
                getMyFriend()
            })
            .catch(err => {
                console.error('not update avatar')
            });
    }

    useEffect(() => {
        if (token) {
            getMyFriend()
        }
    }, [token]);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="content_panel_max_card" id="sectionFriends">
            <div className="title_sup_info"><span>{t('All friends')}</span></div>
            <div className="content_panel_max_card_section">
                {listFriends.length > 0 ? listFriends : 'Пока пусто'}
            </div>
        </div>
    );
}

export default Friends;
