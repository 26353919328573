import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";
import axios from "axios";

function Messages() {
    const {token} = useContext(AuthContext);
    const {t} = useTranslation();
    const [listMsg, setListMsg] = useState([]);

    useEffect(() => {
        if (token) {
            axios.post('https://massaccess.net:9060/public/messageget', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            })
                .then((data) => {
                    setListMsg(data.data.data)
                })
                .catch(err => {
                    console.error('not data')
                });
        }
    }, [token]);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="content_panel_max_card" id="sectionFriends">
            <div className="title_sup_info"><span>{t('Messages')}</span></div>
            <div className="content_panel_max_card_section">
                {listMsg && listMsg.length > 0 ? listMsg.map((item: any) =>
                    <div className='line_msg'>
                        <div className='line_msg_text'>{t(item.comment_info)}</div>
                        <div className='line_msg_id'>{item.name} <span className='line_msg_id_text'>{item.email}</span>
                        </div>
                    </div>
                ) : t('Empty for now')}
            </div>
        </div>
    );
}

export default Messages;
