import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../../component/Auth/AuthContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useTranslation} from "react-i18next";
import axios from "axios";
import ModalSuccess from "../../../../component/Modal/ModalSuccess";


interface Option {
    responsive: any
    handleModalLogin: any
    randomFriends?: boolean | true
}


interface OptionRandomFriends {
    avatar: string,
    name: string,
    id: number,
    email: string
}

function FriendsRandom(props: Option) {
    const {t} = useTranslation();
    const {randomFriends, myFriends, token, getMyFriend} = useContext(AuthContext);
    const [listFriends, setListFriends] = useState([]);
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [messagesModal, setMessagesModal] = useState('');
    const responsive = props.responsive
    // @ts-ignore
    const ButtonGroup = ({next, previous, goToSlide, ...rest}) => {
        const {carouselState: {currentSlide}} = rest;
        return (
            <div className="carousel-button-group">
                <div onClick={() => previous()}></div>
                <div onClick={() => next()}></div>
            </div>
        );
    };
    const handleModal = () => {
        setIsActiveModal(current => !current)
    }

    const addFriend = (id: number) => {
        if (token) {
            axios.post('https://massaccess.net:9060/public/clientsfriendsins', {clientsfriends_id: id}, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    Authorization: 'Bearer ' + token,
                }
            })
                .then((response) => {
                    setMessagesModal('User added as friend')
                    getMyFriend(token)
                    handleModal()
                })
                .catch(err => {
                    console.error(err);
                });

        } else {
            props.handleModalLogin()
        }
    }
    useEffect(() => {
        setListFriends(
            randomFriends.map((isItem: OptionRandomFriends, key: number) =>
                <div className="item_user_card_rf" key={key}>
                    <div className="img_user">
                        <img src={isItem.avatar}/>
                    </div>
                    <div className="title_user">
                        {isItem.name || isItem.email}
                    </div>
                    <div className="btn_add_user">
                        <div className="btn" onClick={() => {
                            addFriend(isItem.id)
                        }}><span>{t("Pal")}</span></div>
                    </div>
                </div>
            )
        )
    }, [randomFriends])

    return (
        <div className="content_panel_max_card">
            <Carousel swipeable={true}
                      responsive={responsive}
                      infinite={true}
                      draggable={false}
                      removeArrowOnDeviceType={["mobile", "tablet"]}
                      arrows={false}
                      renderButtonGroupOutside={true}
                      minimumTouchDrag={10}
                // @ts-ignore
                      customButtonGroup={<ButtonGroup/>}
            >
                {listFriends.map((item, key: number) =>
                    <div key={key}>{item}</div>)
                }
            </Carousel>
            <ModalSuccess isActive={isActiveModal} messages={messagesModal} isLocalize={true}
                          handleModal={handleModal}></ModalSuccess>
        </div>
    );
}

export default FriendsRandom;
