import React from 'react';
import {useTranslation} from "react-i18next";

function FAQ() {
    const {t} = useTranslation();
    return (
        <div className="content_block section">
            <div className="content faq">
                <div className="faq_title">{t('Answers on questions')}</div>
                <div className="faq_two_col">
                    <div className="faq_one_col_items">
                        <div className="faq_one_col_items_item">
                            <ul>
                                <li>{t('General issues')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="faq_two_col_items">
                        <div className="faq_two_col_items_item">
                            <div className="faq_item_title">{t('General issues')}</div>
                            <p>{t('Lottery rules - drawing lottery, the draw is held every Sunday at 20:00.')}</p>
                            <p>{t('The chances of winning are high, guessing from two numbers on the ticket you win big cash prizes, but even without guessing a single number you still get a consolation cash prize.')}</p>
                            <p>{t('You can make a ticket number yourself or the lottery will give you a random one.')}</p>
                            <p>{t('Once every 6 months, the lottery holds a Super-draw, with super-prizes, where all tickets that guess from 2 numbers on the ticket participate in any of the draws for these 6 months.')}
                            </p>
                            <p>{t('Those. your ticket participates 2 times in prize draws.')}</p>
                            <p>{t('In the lottery \"Salute\",')}</p>
                            <p>{t('You can add to the lottery ticket:')}</p>
                            <p>1) {t('friend-friends by giving them a gift, then the prizes will be shared between you and your friends.')}</p>
                            <p>2) {t('add a frame or photo')}</p>
                            <p>3) {t('add congratulations')}</p>
                            <p>{t('Lottery Calendar')}:</p>
                            <p>{t('You will prompt')}</p>
                            <p>1) {t('holidays for every day')}</p>
                            <p>2) {t('important events')}</p>
                            <p>3) {t('birthdays of friends for which you can set the date and time in advance so that the tickets themselves are sent to friends and after the drawing, the prizes are divided between you in equal shares')}</p>
                            <p>{t('The prize fund is 50% of the submitted tickets.')}</p>
                            <p>{t('Category 1 - guessed 0-1 number = 38%')}</p>
                            <p>{t('Category 2 - guessed 2 number = 12%')}</p>
                            <p>{t('Category 3 - guessed 3 number = 10%')}</p>
                            <p>{t('Category 4 - guessed 4 number = 9%')}</p>
                            <p>{t('Category 5 - guessed 5 number = 8%')}</p>
                            <p>{t('Category 6 - guessed 6 number = 6%')}</p>
                            <p>{t('Category 7 - guessed 7 number = 5%')}</p>
                            <p>{t('Category 8 - guessed 8 number = 12%')}</p>
                            <p>{t('Prizes are transferred to your \"Salutloto\" wallet, from which you can transfer them to your personal account.')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
