import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../../component/Auth/AuthContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useTranslation} from "react-i18next";

interface Option {
    responsive: any
    handleModalLogin: any
    select: any
}

interface OptionRandomFriends {
    avatar: string,
    name: string,
    id: number,
    email: string
}

function FriendsMy(props: Option) {
    const {t} = useTranslation();
    const {myFriends} = useContext(AuthContext);
    const [listFriends, setListFriends] = useState([]);
    const responsive = props.responsive;
    // @ts-ignore
    const ButtonGroup = ({next, previous, goToSlide, ...rest}) => {
        const {carouselState: {currentSlide}} = rest;
        return (
            <div className="carousel-button-group">
                <div onClick={() => previous()}></div>
                <div onClick={() => next()}></div>
            </div>
        );
    };

    useEffect(() => {
        if (myFriends && myFriends.length > 0) {
            setListFriends(
                myFriends.map((isItem: OptionRandomFriends, key: number) =>
                    <div className="item_user_card_rf" key={key}>
                        <div className="img_user">
                            <img src={isItem.avatar}/>
                        </div>
                        <div className="title_user">
                            {isItem.name || isItem.email}
                        </div>
                        <div className="btn_add_user">
                            <div className="btn" onClick={() => {
                                props.select(isItem)
                            }}><span>{t("Add")}</span></div>
                        </div>
                    </div>
                )
            )
        }
    }, [myFriends])
    return (
        <div className="content_panel_max_card">
            {listFriends.length > 0 ?
                <Carousel swipeable={true}
                          responsive={responsive}
                          infinite={true}
                          draggable={false}
                          removeArrowOnDeviceType={["mobile", "tablet"]}
                          arrows={false}
                          renderButtonGroupOutside={true}
                          minimumTouchDrag={10}
                    // @ts-ignore
                          customButtonGroup={<ButtonGroup/>}
                >
                    {listFriends.map((item, key: number) =>
                        <div key={key}>{item}</div>)
                    }
                </Carousel> : ''}
        </div>
    );
}

export default FriendsMy;
