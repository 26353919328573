import React from "react"
import {Modal} from './Default/Modal'
import {useTranslation} from "react-i18next"

export interface Option {
    isActive: boolean,
    handleModal: any,
    forgotMsg: string,
    isForgotStatusError: string
}

const ModalForgotStatus = (props: Option) => {
    const {t} = useTranslation()


    return (
        <Modal isOpen={props.isActive} onClose={props.handleModal}>
            <div className="modal_profile_title">
                {props.isForgotStatusError == 'ok' ?
                    t('Password recovery') : t('Password recovery')
                }
            </div>
            <div className="modal_profile_description">
                {props.forgotMsg}
            </div>
        </Modal>
    )
}

export default ModalForgotStatus
