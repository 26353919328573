import React from 'react'

export interface Option {
    isOpen: boolean,
    onClose: any,
    children: any,
    isCloseMouse?: boolean,
    type?: string,
    notOverflow?: boolean
}

export const Modal = (props: Option) => {
    if (!props.isOpen) {
        return null
    }
    const closeBtn = props.isCloseMouse ? <div className="modal_close" onClick={props.onClose}><span>+</span></div> : ''
    return (
        <div className={'modal_window' + ' open ' + props.type}>
            <div className="bg" onClick={props.isCloseMouse ? props.onClose : ''}/>
            <div className={props.notOverflow ? 'modal_content notOverflow' : 'modal_content'}>
                {props.children}
                {closeBtn}
            </div>
        </div>
    )
}

Modal.defaultProps = {
    isCloseMouse: true
}
