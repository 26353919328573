import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {AuthContext} from "../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";

interface Ticket {
    commentPay: any,
    summa: any,
    statusName: any,
}

function Wallet() {
    const {token, balance, currencyName, outBalance} = useContext(AuthContext);
    const [transactions, setTransactions] = useState(false);
    const {t} = useTranslation();

    const topUp = () => {
        axios.post('https://massaccess.net:9060/billing/pay', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((data: any) => {
                window.location = data.data.url;

            })
            .catch(err => {
                console.error(err);
            });
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const getTransaction = () => {
        axios.post('https://massaccess.net:9060/billing/transactions', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((data: any) => {
                setTransactions(
                    data.data.result.map((props: Ticket) =>
                        <div className="wallet_operation">
                            <span className="type_operation">{props.commentPay}</span>
                            <div className="amount_operation">{props.summa} {currencyName}</div>
                            <div className="avatar_friends_operation">{props.statusName}</div>
                        </div>
                    )
                )
            })
            .catch(err => {
                console.error(err);
            });
    }
    const extractWallet = () => {
        axios.post('https://massaccess.net:9060/billing/payout', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((data: any) => {
                window.location = data.data.url;
            })
            .catch(err => {
                console.error(err);
            });
    }
    useEffect(() => {
        if (token && token.length > 0) {
            getTransaction()
        }
    }, [token]);

    return (
        <div className="content_panel_max_card">
            <div className="content_panel_max_card_section">
                <div className="btn" onClick={topUp}><span>{t('Top up')}</span></div>
                <div className="btn" onClick={extractWallet}><span>{t('Withdraw')}</span></div>
            </div>
            <div className="title_sup_info"><span>{t('Balance')}</span></div>
            <div className="content_panel_max_card_section">
                <div className="">{t('Current balance')}: <span>{balance} {currencyName}</span></div>
                <div className="">{t('Available for withdrawal')}: <span>{outBalance} {currencyName}</span></div>
            </div>
            <div className="title_sup_info"><span>{t('Operations history')}</span></div>
            <div className="content_panel_max_card_section">
                <div className="sectionWallet">
                    <div className="wallet_operation">
                        <span className="type_operation header_table_profile">{t('Type of transaction')}</span>
                        <div className="amount_operation header_table_profile">{t('Amount')}</div>
                        <div className="avatar_friends_operation header_table_profile">{t('Status')}</div>
                    </div>
                    {transactions}
                </div>
            </div>
        </div>
    );
}

export default Wallet;
