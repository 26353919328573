import React, {useContext} from "react"
import {Modal} from './Default/Modal'
import {useTranslation} from "react-i18next"
import {AuthContext} from "../Auth/AuthContext"
import FriendsMy from "../../page/Main/Component/FriendsMy/FriendsMy"
import SelectWithSearchFriends from "../../page/Main/Component/SelectWithSearchFriends/SelectWithSearchFriends"

export interface OptionModal {
    isActive: boolean,
    handleModal: any,
    selectFriends?: any
    handleModalLogin?: any
}

const ModalAddFriends = (props: OptionModal) => {
    const {t} = useTranslation()
    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 18000, min: 1200},
            items: 4
        },
        desktop: {
            breakpoint: {max: 1200, min: 700},
            items: 4
        },
        tablet: {
            breakpoint: {max: 700, min: 460},
            items: 2
        },
        mobile: {
            breakpoint: {max: 460, min: 0},
            items: 2
        }
    }

    return (
        <Modal isOpen={props.isActive} onClose={props.handleModal} type={'middle big'}>
            <div id="itemFriends">
                <div className="modal_profile_title">{t('Add a friend to your ticket')}</div>
                <div className="input_section">
                    <SelectWithSearchFriends select={props.selectFriends} className="inputNameFriend"
                                             handleModalLogin={props.handleModalLogin}/>
                    <div className="input_section_item">
                        <div className="input_item_icon"></div>
                        <div className="input_item_filter"></div>
                    </div>
                </div>
                <div className="all_user slider">
                    <div className="all_user_section slides" id="all_random_user_section_rf_add_ticket">
                        <FriendsMy handleModalLogin={props.handleModalLogin} select={props.selectFriends}
                                   responsive={responsive}></FriendsMy>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalAddFriends
