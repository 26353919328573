import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";

function NotWorkRegion() {
    const {t} = useTranslation()
    useEffect(() => {
    }, []);
    return (
        <div className="content_block">
            <div className="text_center">
                {t('Not work region')}
            </div>
        </div>
    );
}

export default NotWorkRegion;
