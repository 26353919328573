import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/style/img/svg/logo.svg'
import {useTranslation} from "react-i18next"


function Footer() {
    const {t} = useTranslation()
    const handleClick = () => {
        setTimeout(()=>{
        const hash = window.location!.hash.split('#')[1] || ''
        if (hash != '' && document.querySelector('#' + hash)) {
            const y = document.getElementById(hash)!.getBoundingClientRect().top + window.pageYOffset - 90
            window.scrollTo({top: y, behavior: 'smooth'})
        }
        },100)
    }
    return (
        <div className="section footer">
            <div className="content">
                <div className="footer_logo">
                    <Link to="/" className="header_logo_href">
                        <img src={logo} alt="" className="logo_img"/>
                    </Link>
                </div>
                <div className="footer_info">
                    <div className="footer_info_btn"><Link to="/#how-it-works" onClick={handleClick}>{t('howItWorks')}?</Link></div>
                    <div className="footer_info_btn"><Link to="/#buy" onClick={handleClick}>{t('Buy a postcard ticket')}</Link></div>
                    <div className="footer_info_btn"><Link to="mailto:info@salutloto.com">info@salutloto.com</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
