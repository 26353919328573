import React, {useState, useEffect, useContext} from 'react';
import Select from 'react-select';
import {AuthContext} from "../../../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";
import axios from "axios";

export interface Option {
    className: string,
    handleModalLogin: any
    select?: any
}

const SelectWithSearch = (props: Option) => {
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState([]);
    const {token, isAuthenticated, getMyFriend} = useContext(AuthContext);
    const {t} = useTranslation();

    useEffect(() => {
        if (isAuthenticated) {
            const searchOnServer = async () => {
                try {
                    const response = await fetch("https://massaccess.net:9060/public/clientsearchget",
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'accept': 'application/json',
                                Authorization: 'Bearer ' + token,
                            },
                            body: JSON.stringify({name: searchValue})
                        });

                    const data = await response.json();
                    if (data.data) {
                        data.data.map((item: any) => {
                            item.label = item.name || item.email
                            item.value = item.friends_id
                        })
                        setOptions(data.data);
                    }

                } catch (error) {
                    console.error('Ошибка при выполнении поиска', error);
                }
            };

            if (searchValue.trim() !== '') {
                searchOnServer();
            } else {
                setOptions([]);
            }
        } else {
            if (searchValue != '') {
                props.handleModalLogin()
            }
        }
    }, [searchValue]);
    const addFriend = (item: any) => {
        axios.post('https://massaccess.net:9060/public/clientsfriendsins', {clientsfriends_id: item.value}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((response) => {
                item.name = item.name || item.email
                props.select(item)
                getMyFriend(token)
            })
            .catch(err => {
                console.error(err);
            });
    }
    const getOptionLabel = (option: any) => {
        // Здесь вы можете вернуть кастомное отображение `option` label
        return (
            <div className="elementSearchLine">
                <div className="avatar"><img src={option.avatar}/></div>
                <div className="text">{option.label}</div>
                <div className="section_btn">
                    <div className="btn search" onClick={() => {
                        addFriend(option)
                    }}>{t('Add')}
                    </div>
                </div>
            </div>
        );
    };


    const handleChange = (selectedOption: any) => {
        addFriend(selectedOption)
    };

    const isAuth = () => {
        if (!isAuthenticated) {
            props.handleModalLogin()
        }
    }
    return (
        <Select
            options={options}
            placeholder={t('E-mail, phone number, full name')}
            isSearchable={true}
            onInputChange={(inputValue) => setSearchValue(inputValue)}
            onChange={handleChange}
            onFocus={isAuth}
            formatOptionLabel={getOptionLabel}
            className={props.className}
            classNamePrefix={'selectFriend'}
            value={''}
        />
    );
};

export default SelectWithSearch;
