import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../component/Auth/AuthContext";

function Confirm() {
    const {t} = useTranslation();
    const {confirm, statusConfirm} = useContext(AuthContext);
    const [statusConfirmMes, setStatusConfirmMes] = useState('');

    useEffect(() => {
        let guid = window.location.search.split('=')[1]
        confirm(guid)
    }, [])
    useEffect(() => {
        if (statusConfirm) {
            if (statusConfirm && statusConfirm.status && statusConfirm.status == 200) {
                setStatusConfirmMes('true')
            } else if (statusConfirm && statusConfirm.response && statusConfirm.response.status) {
                setStatusConfirmMes('false')
            } else {
                console.log('Error 1')
            }
        }
    }, [statusConfirm])

    return (
        <div className="content_block">
            <div className="section registration">
                <div className="content">
                    <div className="modal_reg">
                        {statusConfirmMes != '' ?
                            statusConfirmMes == 'true' ?
                                <div className="title_reg">{t('Email confirmed')}</div>
                                :
                                <div className="title_reg">{t('Confirmation error')}</div>
                            :
                            <div className="title_reg">{t('Please wait')}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Confirm;
