import React, {useContext, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {AuthContext} from "../Auth/AuthContext";

export interface Option {
    isActiveLeftMenu: Boolean,
    triggerLeftMenu: any,
    handleModalLogin: any
}

const LeftMenu = (props: Option) => {
    const {t} = useTranslation();
    const {isAuthenticated, avatar, balance, name, currencyName, logout} = useContext(AuthContext);
    const nav = useNavigate();

    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
        setIsActive(current => !current);
        const hash = window.location!.hash.split('#')[1] || '';
        if (hash != '' && document.querySelector('#' + hash)) {
            const y = document.getElementById(hash)!.getBoundingClientRect().top + window.pageYOffset - 90;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    };
    const handleClickLogin = () => {
        props.handleModalLogin();
    };
    const hideMenu = (e: any) => {
        props.triggerLeftMenu()

    }
    const exit = () => {
        logout();
        nav('/')
    }
    return (
        <div id="leftMenu" onClick={hideMenu} className={'left_menu ' + (props.isActiveLeftMenu ? 'open' : '' + '')}>
            <div className="content_left_menu_bg"></div>
            <div className="content_left_menu">
                <div className="btn_close"><span>+</span></div>
                {isAuthenticated ?
                    <div className={'left_menu_group' + (isAuthenticated ? ' auth' : '' + '')} id="left_menu_group">
                        <div className="modal_profile_auth_profile">
                            <div className="modal_profile_auth_profile_img" id="profile_menu_item">
                                <img src={avatar ? avatar : ''}/>
                            </div>
                            <div className="modal_profile_auth_profile_name"><span>{name}</span></div>
                            <div className="modal_profile_auth_profile_balance">
                                <span>{balance} {currencyName}</span></div>
                        </div>
                        <Link to='/profile' className="menu_item">{t('Profile')}</Link>
                        <Link to='/profile/tickets' className="menu_item">{t('My tickets')}</Link>
                        <Link to='/profile/friends' className="menu_item">{t('My friends')}</Link>
                        <Link to='/profile/wallet' className="menu_item">{t('My finances')}</Link>
                        <Link to='/profile/messages' className="menu_item">{t('Messages')}</Link>
                        <div className="menu_item" onClick={exit}>{t('Exit')}</div>
                        <span className="separator"></span>
                        <Link to='/#how-it-works' className="menu_item" onClick={handleClick}>{t('howItWorks')}?</Link>
                        <Link to='/drawraffleget' className="menu_item">{t('Draw results')}</Link>
                        <Link to='/faq' className="menu_item">{t('Answers on questions')}</Link>
                        <span className="separator"></span>
                        <a className="menu_item_doc" href="/documents/Terms_and_Conditions.pdf"
                           target="_blank">{t('Terms of Service')}</a>
                        <a className="menu_item_doc" href="/documents/Responsible_gambling.pdf"
                           target="_blank">{t('Responsible Gaming')}</a>
                        <a className="menu_item_doc" href="/documents/Dispute_Resolution.pdf"
                           target="_blank">{t('Dispute Resolution')}</a>
                        <a className="menu_item_doc" href="/documents/AML_KYC_Policy.pdf"
                           target="_blank">{t('Anti-Money Laundering')}</a>
                        <a className="menu_item_doc" href="/documents/Privacy_Policy.pdf"
                           target="_blank">{t('KYC Policies')}</a>
                    </div>
                    : (
                        <div className="left_menu_group" id="left_menu_group">
                            <Link to='/' className="menu_item" onClick={handleClickLogin}>{t('Sign in')}</Link>
                            <Link to='/registration' className="menu_item">{t('Registration')}</Link>
                            <span className="separator"></span>
                            <Link to='/#how-it-works' className="menu_item"
                                  onClick={handleClick}>{t('howItWorks')}?</Link>
                            <Link to='/drawraffleget' className="menu_item">{t('Draw results')}</Link>
                            <Link to='/faq' className="menu_item">{t('Answers on questions')}</Link>
                            <span className="separator"></span>
                            <a className="menu_item_doc" href="/documents/Terms_and_Conditions.pdf"
                               target="_blank">{t('Terms of Service')}</a>
                            <a className="menu_item_doc" href="/documents/Responsible_gambling.pdf"
                               target="_blank">{t('Responsible Gaming')}</a>
                            <a className="menu_item_doc" href="/documents/Dispute_Resolution.pdf"
                               target="_blank">{t('Dispute Resolution')}</a>
                            <a className="menu_item_doc" href="/documents/AML_KYC_Policy.pdf"
                               target="_blank">{t('Anti-Money Laundering')}</a>
                            <a className="menu_item_doc" href="/documents/Privacy_Policy.pdf"
                               target="_blank">{t('KYC Policies')}</a>
                        </div>
                    )}
                <div className="left_menu_group_btn">
                    <Link to='/#buy' className="btn menu_item" onClick={handleClick}>{t('To buy a ticket')}?</Link>
                </div>
            </div>
        </div>
    )
}

export default LeftMenu;
