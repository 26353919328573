import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../../component/Auth/AuthContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";


interface Option {
    responsive: any,
    select: any
}

interface OptionRandomFriends {
    avatar: string,
    name: string,
    friends_id: number,
    email: string
}

function FriendsMyCircle(props: Option) {
    const {myFriends, token, getMyFriend, getCookies} = useContext(AuthContext);
    const [listFriends, setListFriends] = useState([]);

    const responsive = props.responsive;
    // @ts-ignore
    const ButtonGroup = ({next, previous, goToSlide, ...rest}) => {
        const {carouselState: {currentSlide}} = rest;
        return (
            <div className="carousel-button-group">
                <div onClick={() => previous()}></div>
                <div onClick={() => next()}></div>
            </div>
        );
    };
    const itemAddTicket = (data: OptionRandomFriends) => {
        if (!data.name) data.name = data.email
        props.select(data)
    }
    const delFriends = (id: number) => {
        if (token != '') {
            axios.post('https://massaccess.net:9060/public/clientsfriendsdel', {clientsfriends_id: id}, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            })
                .then((data) => {
                    getMyFriend(token)
                })
                .catch(err => {
                    console.error('not update avatar')
                });
        }
    }
    useEffect(() => {
        if (myFriends && myFriends.length > 0) {
            setListFriends(
                myFriends.map((isItem: OptionRandomFriends, key: number) =>
                    <div className="item_user_card_circle" key={key}>
                        <div className="img_user" onClick={() => itemAddTicket(isItem)}>
                            <img src={isItem.avatar}/>
                        </div>
                        <div className="title_user" onClick={() => itemAddTicket(isItem)}>
                            {isItem.name || isItem.email}
                        </div>
                        <div className="btn_close" onClick={() => {
                            delFriends(isItem.friends_id)
                        }}>
                            <span>+</span>
                        </div>
                    </div>
                )
            )
        }
    }, [myFriends])
    return (
        <div className="content_panel_max_card">
            {listFriends.length > 0 ?
                <Carousel swipeable={true}
                          responsive={responsive}
                          infinite={true}
                          draggable={false}
                          removeArrowOnDeviceType={["mobile", "tablet"]}
                          arrows={false}
                          renderButtonGroupOutside={true}
                          minimumTouchDrag={10}
                    // @ts-ignore
                          customButtonGroup={<ButtonGroup/>}
                >
                    {listFriends.map((item, key: number) =>
                        <div key={key}>{item}</div>)
                    }
                </Carousel> : ''}
        </div>
    );
}

export default FriendsMyCircle;
