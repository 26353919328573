import React, {useEffect} from "react";
import {Modal} from './Default/Modal';
import {useTranslation} from "react-i18next";

export interface Option {
    isActive: boolean
    messages: string
    handleModal: any
    headerText?: string
    callback?: any
    isLocalize?: boolean
    btnText?: string
}

const ModalMessages = (props: Option) => {
    const {t} = useTranslation();
    const clickOk = () =>{
        props.handleModal()
        if(props.callback){
            props.callback()
        }
    }
    return (
        <Modal isOpen={props.isActive} onClose={props.handleModal}>
            <div className="modal_profile_title">
                {props.isLocalize ? t(props.headerText || "") : props.headerText}
            </div>
            <div className="modal_content_info">
                {props.isLocalize ? t(props.messages || "") : props.messages}
            </div>
            <div className="modal_profile_btn btn_bg btn" onClick={clickOk}>
                {props.isLocalize ? t(props.btnText || "Ok") : props.btnText}
            </div>
        </Modal>
    );
};
export default ModalMessages;
