import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../component/Auth/AuthContext";
import {Modal} from "../../component/Modal/Default/Modal";
import {useForm} from "react-hook-form";

type Inputs = {
    password: string;
    password_repeat: string
};

function Forgot() {
    const {t} = useTranslation();
    const nav = useNavigate();
    let {guid} = useParams();
    const {forgotPass, statusForgot,} = useContext(AuthContext);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const {
        register,
        getValues,
        handleSubmit,
        formState: {errors}
    } = useForm<Inputs>();

    const onSubmit = (data: any) => {
        forgotPass(guid, data.password)
    };

    const type = ''

    const handleModal = () => {
        setIsSuccess(!isSuccess)
        nav('/')
    }
    const handleModalError = () => {
        setIsError(!isError)
    }
    const handleModalSuccess = () => {
        setIsSuccess(!isSuccess)
    }
    useEffect(() => {
        if (statusForgot === 'err') {
            setIsError(true)
        }
        if (statusForgot === 'ok') {
            setIsSuccess(true)
        }
    }, [statusForgot])
    return (
        <div className="content_block">
            <div className="section registration">
                <div className="content">
                    <div className="modal_reg">
                        <div className="title_reg">{t('Password recovery')}</div>
                        <form className="form_reg" onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_block_reg">
                                <input
                                    type="password"
                                    className={errors.password ? 'error' : ''}
                                    placeholder={t('Password')}
                                    {...register('password', {
                                        required: "You must specify a password",
                                        minLength: {
                                            value: 6,
                                            message: "Password must have at least 6 characters"
                                        }
                                    })}
                                />
                                {errors.password && <p>{errors.password.message}</p>}
                            </div>
                            <div className="input_block_reg">
                                <input
                                    type="password"
                                    className={errors.password_repeat ? 'error' : ''}
                                    placeholder={t('Repeat password')}
                                    {...register('password_repeat', {
                                        validate: value => {
                                            return value === getValues('password') || "The passwords do not match"
                                        }
                                    })}
                                />
                                {errors.password_repeat && <p>{errors.password_repeat.message}</p>}
                            </div>
                            <button className="btn btn_bg btn_reg" type="submit">{t('Restore password')}</button>
                        </form>
                    </div>
                </div>
            </div>
            <Modal isOpen={isSuccess} onClose={handleModalSuccess} type={type}>
                <div className="modal_content_title">{t('Error')}</div>
                <div className="modal_content_info">
                    {t('Something went wrong, please try again later')}
                </div>
                <div className="modal_profile_btn btn_bg btn" onClick={handleModalSuccess}>{t('Ok')}</div>
            </Modal>
            <Modal isOpen={isError} onClose={handleModalError} type={type}>
                <div className="modal_content_title">{t('Error')}</div>
                <div className="modal_content_info">
                    {t('Something went wrong, please try again later')}
                </div>
                <div className="modal_profile_btn btn_bg btn" onClick={handleModalError}>{t('Ok')}</div>
            </Modal>
        </div>
    );
}

export default Forgot;
