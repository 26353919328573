import React, {useContext} from 'react';
import {AuthContext} from "../../../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";

interface List {
    list: any
}

function ItemList(props: List) {
    const {avatar} = useContext(AuthContext);
    const {t} = useTranslation();


    const dataTimeFormat = (ticket: any) => {
        let dataTimeEl = false,
            dataDraw = '',
            timeDraw = '';
        // @ts-ignore
        Date.prototype.today = function () {
            return ((this.getDate() < 10) ? "0" : "") + this.getDate() + "." + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "." + this.getFullYear();
        }
        // @ts-ignore
        Date.prototype.timeNow = function () {
            return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes() + ":" + ((this.getSeconds() < 10) ? "0" : "") + this.getSeconds();
        }
        if (ticket.drawdatetime) {
            dataTimeEl = true
            let newDate = new Date(ticket.drawdatetime);
            // @ts-ignore
            dataDraw = '' + new Date(newDate).today();
            // @ts-ignore
            timeDraw = '' + new Date(newDate).timeNow();
        }
        return <div className="item_ticket_center">
            <div className="draw_name">{ticket.drawname}</div>
            {dataTimeEl ? <div className="draw_date">{t('Date')}: {dataDraw}</div> : ""}
            {dataTimeEl ? <div className="draw_time">{t('Time')}: {timeDraw}</div> : ""}
        </div>
    }
    return (
        <div className="items_tickets">
            {props.list.map((ticket: any) => {
                return <div className={'item_ticket'}>
                    <div className="item_ticket_container">
                        <div className="item_ticket_bg">
                            {ticket.ticketbackground ? <img src={ticket.ticketbackground} alt=""/> : ""}
                        </div>
                        <div className="item_ticket_top">
                            {ticket.frends_present_id ?
                                <div className="isPresent">
                                    <div className="isPresentBlock">
                                        <div className="isPresentStatus isOk"><span></span></div>
                                        <div className="isPresentUser"><img
                                            src={ticket.frends_present_id[0].avatarbackground}/></div>
                                    </div>
                                    <div
                                        className="isPresentUserName">{ticket.frends_present_id[0].name || ticket.frends_present_id[0].email}</div>
                                </div>
                                : ''}
                            <div className="item_ticket_mini_input"><span>{ticket.ticketcomment}</span></div>
                        </div>
                        {dataTimeFormat(ticket)}
                        <div className="item_ticket_center">
                            <div className="ticket_number">{t('Number')}: №{ticket.ticketnumber}</div>
                            <div className="ticket_amount">{t('Price')}: {ticket.price} {ticket.iso}</div>
                            <div
                                className="ticket_win">{ticket.summawincur ? (t('Winnings') + ":" + ticket.summawincur + " " + ticket.iso) : ""}</div>
                        </div>
                        <div className="item_ticket_bottom">
                            <div className="item_ticket_mini">
                                <div className="item_ticket_mini_friends">
                                    <div className="item_ticket_mini_friend_cont">
                                        <div className="item_ticket_mini_friend"><img src={avatar}/></div>
                                    </div>
                                    {ticket.frends_id && ticket.frends_id.length > 0 ? ticket.frends_id.map((item: any) => {
                                        return <div className="item_ticket_mini_friend_cont">
                                            <div className="item_ticket_mini_friend"><img src={item.avatarbackground}/>
                                            </div>
                                        </div>
                                    }) : ''}
                                </div>
                            </div>
                        </div>
                        <div
                            className={ticket.statusname == "Розыгранный" ? ticket.summawincur && ticket.summawincur > 0 ? "item_ticket_status win" : "item_ticket_status null" : "item_ticket_status new"}>
                            <span className="pin"></span>
                        </div>
                    </div>
                </div>
            })}
        </div>
    );
}

export default ItemList;
