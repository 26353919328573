import React, {useState, useContext, useEffect} from "react"
import {Modal} from './Default/Modal'
import axios from 'axios'
import {useTranslation} from "react-i18next"

export interface Option {
    isActive: boolean,
    handleModal: any,
    handleModalForgotStatus: any
}

const ModalForgot = (props: Option) => {
    const {t} = useTranslation()
    const [email, setEmail] = useState('')
    const getCookies = (name: any) => {
        let res = ''
        document.cookie.split(';').forEach(c => {
            if (c.trim().startsWith(name))
                res += decodeURIComponent(c) + ''
        })
        return res
    }
    const lang = getCookies('salut_lang').split('=')[1]
    const sendForgot = () => {
        axios.post('https://massaccess.net:9060/public/forgotpassword', {'email': email, lang:lang}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + getCookies('sl-auth-token').split('=')[1],
            }
        })
            .then((response) => {
                props.handleModalForgotStatus('ok', response.data.message)
            }).catch((data) => {
            props.handleModalForgotStatus('err', data.response.data.message)
        })
    }

    return (
        <Modal isOpen={props.isActive} onClose={props.handleModal}>
            <div className="modal_profile_title">{t('Password recovery')}</div>
            <div className="modal_profile_form">
                <div className="modal_profile_input_block">
                    <input type="text" className="modal_profile_input" id="email"
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           placeholder={t('Email')}/>
                </div>
            </div>
            <div className="modal_profile_btn btn_bg btn" onClick={sendForgot}>{t('Restore password')}</div>
        </Modal>
    )
}

export default ModalForgot
