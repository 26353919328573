import React, {useContext, useEffect, useState} from "react"
import {Modal} from './Default/Modal'
import axios from "axios"
import {AuthContext} from "../Auth/AuthContext"
import {useTranslation} from "react-i18next"
import Select from "react-select"

export interface Option {
    isActive: boolean,
    handleModal: any
}

const ModalGetCurrency = (props: Option) => {
    const {token, listCurrency} = useContext(AuthContext)
    const {t} = useTranslation()
    const [selectedCurrency, setSelectedCurrency] = useState()
    const [listCurrencyShow, setListCurrencyShow] = useState()


    const sendSelectCurrencyProfile = () => {
        axios.post('https://massaccess.net:9060/public/clientcurrenciesins', {"currencies_id": selectedCurrency}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((data) => {
                if (data.data) {
                    props.handleModal()
                }
            })
            .catch(err => {
                console.error(err)
            })
    }
    useEffect(() => {
        if (listCurrency && listCurrency.length > 0) {
            listCurrency.map((item: any) => {
                item.label = item.name
                item.value = item.id
            })
            setListCurrencyShow(listCurrency)
        }
    }, [listCurrency])
    return (
        <Modal isOpen={props.isActive} onClose={props.handleModal} isCloseMouse={false} notOverflow={true}>
            <div className="modal_description">
                {t('Select currency')}:
            </div>

            <div className="modalItemSelect">
                <Select name="itemCurrency" onChange={(e: any) => {
                    setSelectedCurrency(e.value)
                }}
                        options={listCurrencyShow}
                        classNamePrefix={'currency'}
                        className={'selectCurrency'}/>
            </div>
            <div className="modal_action">
                <div className="btn" onClick={sendSelectCurrencyProfile}>{t('Choose')}</div>
            </div>
        </Modal>
    )
}

export default ModalGetCurrency
