import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";
import ModalChangeAvatar from "../../component/Modal/ModalChangeAvatar";

interface Option {
    isEdit?: boolean,
    children: any,
    active: string
}

function Cabinet(props: Option) {
    const nav = useNavigate();
    const {name, avatar, isAuthenticated, logout} = useContext(AuthContext);
    const {t} = useTranslation();
    const [isActiveAvatar, setIsActiveAvatar] = useState(false);

    const getCookies = (name: string) => {
        let res = '';
        document.cookie.split(';').forEach(c => {
            if (c.trim().startsWith(name))
                res += decodeURIComponent(c) + '';
        });
        return res;
    }
    const changeAvatar = () => {
    }
    const handleModalAvatar = () => {
        setIsActiveAvatar(current => !current);
    };
    const exit = () => {
        logout();
        nav('/')
    }

    useEffect(() => {
        if (!getCookies('auth_token')) {
            nav('/')
        }
    }, [isAuthenticated])

    return (
        <div className="section content_block">
            <div className="content">
                <div className="content_two_panel_header">{t('')}</div>
                <div className="content_two_panel">
                    <div className="content_panel_min">
                        <div className="profile_photo_card">
                            <div className="profile_photo_card_item" id="profile_avatar">
                                <img src={avatar}/>
                            </div>
                            {props.isEdit ?
                                <div onClick={handleModalAvatar} className="profile_photo_card_change"></div> : ''}
                        </div>
                        <div className="profile_name_card">
                            <div className="profile_name_card_item" id="profile_name">
                                {name}
                            </div>
                        </div>
                        <div className="profile_exit_card">
                            <div className="btn" onClick={exit}>
                                <span>{t('Exit')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="content_panel_max">
                        <Link
                            className={props.active == 'profile' ? "content_panel_max_header active" : "content_panel_max_header"}
                            to="/profile"><span>{t('Profile')}</span></Link>
                        <Link
                            className={props.active == 'tickets' ? "content_panel_max_header active" : "content_panel_max_header"}
                            to="/profile/tickets"><span>{t('My tickets')}</span></Link>
                        <Link
                            className={props.active == 'messages' ? "content_panel_max_header active" : "content_panel_max_header"}
                            to="/profile/messages"><span>{t('Messages')}</span></Link>
                        <Link
                            className={props.active == 'friends' ? "content_panel_max_header active" : "content_panel_max_header"}
                            to="/profile/friends"><span>{t('My friends')}</span></Link>
                        <Link
                            className={props.active == 'wallet' ? "content_panel_max_header active" : "content_panel_max_header"}
                            to="/profile/wallet"><span>{t('My finances')}</span></Link>
                        {props.children}
                    </div>
                </div>
            </div>
            <ModalChangeAvatar handleModal={handleModalAvatar} isActive={isActiveAvatar} changeBg={changeAvatar}/>
        </div>
    );
}

export default Cabinet;
