import React, {createContext, useEffect, useState} from 'react'

import axios from "axios"

export const AuthContext = createContext()

let updateToken = false
export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [token, setToken] = useState('')
    const [avatar, setAvatar] = useState(false)
    const [name, setName] = useState(false)
    const [phone, setPhone] = useState(false)
    const [dateBirth, setDateBirth] = useState(false)
    const [clientSex, setClientSex] = useState(false)
    const [email, setEmail] = useState(false)
    const [surname, setSurname] = useState(false)
    const [currenciesIso, setCurrenciesIso] = useState(false)
    const [idUser, setIdUser] = useState(false)
    const [balance, setBalance] = useState(false)
    const [currencyName, setCurrencyName] = useState('')
    const [outBalance, setOutBalance] = useState(false)
    const [allTicketBasket, setAllTicketBasket] = useState([])
    const [currenciesId, setCurrenciesId] = useState([])
    const [listCurrency, setListCurrency] = useState()
    const [isMobile, setIsMobile] = useState(false)
    const [isGoMain, setIsGoMain] = useState(false)

    const [randomFriends, setRandomFriends] = useState([])
    const [ticketBackground, setTicketBackground] = useState([])
    const [drawCurrent, setDrawCurrent] = useState([])
    const [ticketNumber, setTicketNumber] = useState([])
    const [ticketCurrentBuy, setTicketCurrentBuy] = useState([])
    const [statusRegistration, setStatusRegistration] = useState('')
    const [statusRegistrationMessages, setStatusRegistrationMessages] = useState('')
    const [statusForgot, setStatusForgot] = useState('')
    const [statusConfirm, setStatusConfirm] = useState()
    const [statusLogin, setStatusLogin] = useState()
    const [myFriends, setMyFriends] = useState([])
    const [langUser, setLangUser] = useState('')
    const [langsList, setLangsList] = useState([])

    const getCookies = (name) => {
        let res = ''
        document.cookie.split(';').forEach(c => {
            if (c.trim().startsWith(name))
                res += decodeURIComponent(c) + ''
        })
        return res
    }
    let defaultTokenSl = getCookies('sl-auth-token').split('=')[1]
    let BlockSite = getCookies('not-work').split('=')[1]


    const setCookie = (name, value, time, path, domain, secure) => {
        let date = new Date()
        date.setTime(date.getTime() + (time * 1000))
        document.cookie = name + '=' + value +
            '; path=/' +
            '; expires=' + date.toUTCString() +
            '; domain=' + ((domain) ? domain : '') +
            '; secure=' + ((secure) ? secure : '')
    }
    const getCurrencies = (storedToken) => {
        axios.post('\n' +
            'https://massaccess.net:9060/public/currencies', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + storedToken,
            }
        }).then((data) => {
            setListCurrency(data.data.data)
        }).catch(err => {
            console.error(err)
        })
    }
    const initNotAuth = () => {
        if (BlockSite !== 'yes') {
            getNowDraw()
            getRandomFriends()
            getTicketBackground()
            getDrawCurrent()
            getTicketCurrentBuy()
            getLanguage()
        }
    }

    const initAuth = (token) => {
        if (BlockSite !== 'yes') {
            setToken(token)
            getNowDraw()
            getRandomFriends()
            getTicketBackground()
            getDrawCurrent()
            getTicketCurrentBuy()
            getClientSingle(token)
            getLanguage()
        }
    }


    const getRandomFriends = () => {
        axios.post('https://massaccess.net:9060/public/randomfriends', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + defaultTokenSl,
            }
        })
            .then((response) => {
                setRandomFriends(response.data.data)
            })
            .catch(err => {
                console.error('getRandomFriends: ', err)
            })
    }
    const getNowDraw = () => {
        axios.post('https://massaccess.net:9060/public/realtimedraw', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + defaultTokenSl,
            }
        })
            .then((response) => {
                let start = new Date()
                let end = new Date(response.data.data.drawdatetime)
                let diff = Date.parse(end) - Date.parse(start)
                setTimeout(getNowDraw, diff)
                console.log('getNowDraw',response.data.data, diff)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const getTicketBackground = () => {
        axios.post('https://massaccess.net:9060/public/ticketstandardbackground', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + defaultTokenSl,
            }
        })
            .then((response) => {
                setTicketBackground(response.data.data)
            })
            .catch(err => {
                console.error('getTicketBackground: ', err)
            })
    }
    let i = 0
    const getDrawCurrent = () => {
        if (i === 0) {
            i++
            setTimeout(() => {
                i = 0
            }, 1000)
            axios.post('https://massaccess.net:9060/public/drawcurrent', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    Authorization: 'Bearer ' + defaultTokenSl,
                }
            }).then((response) => {
                setDrawCurrent(response.data.data)
                getTicketNumber(response.data.data[response.data.data.length - 1].drawraffle_id)
                let min = 1000000
                response.data.data.map((item) => {
                    let diff = Number(new Date(item.datestart)) - Number(new Date())
                    if (min > diff) {
                        min = diff
                    }
                })
                setTimeout(getDrawCurrent, min + 15000)
            }).catch(err => {
                console.error('getDrawCurrent: ', err)
            })
        }
    }

    const getTicketNumber = (id) => {
        axios.post('https://massaccess.net:9060/public/randomnumberdrawraffle', {"drawraffle_id": id}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + defaultTokenSl,
            }
        }).then((response) => {
            setTicketNumber(response.data.data)
        }).catch(err => {
            console.error('getTicketNumber: ', err)
        })
    }

    const getTicketCurrentBuy = () => {
        axios.post('https://massaccess.net:9060/public/ticketcurrentbuy', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + defaultTokenSl,
            }
        })
            .then((response) => {
                setTicketCurrentBuy(response.data.data)
            })
            .catch(err => {
                console.error('getTicketCurrentBuy: ', err)
            })
    }


    const getMyFriend = (tokenSign) => {
        axios.post('https://massaccess.net:9060/public/clientsfriendsget', {p_page: 1}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + tokenSign
            }
        })
            .then((data) => {
                if (data && data.data) {
                    setMyFriends(data.data.data)
                }
            })
            .catch(err => {
                console.error('not my friends')
            })
    }
    const getClientSingle = (token) => {
        axios.post('https://massaccess.net:9060/public/clientsingl', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((response) => {
                setCurrenciesId(response.data.data.currenciesid)
                getBasketTicket()
                getBalances(token)
                setAvatar(response.data.data.avatar)
                setName(response.data.data.name)
                setSurname(response.data.data.surname)
                setPhone(response.data.data.phone)
                setDateBirth(response.data.data.date_birth)
                setClientSex(response.data.data.clientsex_id)
                setEmail(response.data.data.email)
                setCurrenciesIso(response.data.data.currenciesiso)
                setIdUser(response.data.data.id)
                getMyFriend(token)
                getCurrencies(token)
                setLangUser(response.data.data.lang)
                if (window.location.pathname.split('/')[1] === 'forgot_password') {
                    setIsGoMain(true)
                }
            })
            .catch(err => {
                if (!updateToken) {
                    updateToken = true
                    updateTokenFn(token)
                }
                console.error('getTicketCurrentBuy: ', err)
            })
    }

    /*Пока единственное место куда есть доступ для не авторизованных, токен проверяем тут*/
    const getBasketTicket = () => {
        let tokenBasket = getCookies('auth_token').split('=')[1]
        axios.post('https://massaccess.net:9060/public/basketticket', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + tokenBasket,
            }
        })
            .then((data) => {
                setAllTicketBasket(data.data.data)
            })
            .catch(err => {
                console.error(err)
            })
    }
    const updateTokenFn = (storedToken) => {
        axios.post('https://massaccess.net:9060/content/token', {"token": storedToken}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + storedToken,
            }
        })
            .then((data) => {
                setCookie('auth_token', data.data.data.token, 86400)
                setToken(data.data.data.token)
            })
            .catch(err => {
                console.log('Error 5')
            })
    }

    const getBalances = (tokenItem) => {
        axios.post('\n' +
            'https://massaccess.net:9060/billing/balances', {}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + tokenItem,
            }
        }).then((data) => {
            setBalance(data.data.result[0].balanceAmount)
            setCurrencyName(data.data.result[0].currencyName)
            setOutBalance(data.data.result[0].outputAmount)
        }).catch(err => {
            console.error(err)
        })
    }

    const handleWindowSizeChange = () => {
        window.addEventListener('resize', handleWindowSizeChange)
        let isMob = window.innerWidth <= 768
        setIsMobile(isMob)
    }

    useEffect(() => {
        defaultTokenSl = getCookies('sl-auth-token').split('=')[1]
        const storedToken = getCookies('auth_token').split('=')[1]
        if (storedToken) {
            setIsAuthenticated(true)
            initAuth(storedToken)
        } else {
            setIsAuthenticated(false)
            initNotAuth(defaultTokenSl)
        }
        handleWindowSizeChange()
    }, [])

    // Функции для входа и выхода из системы
    let langs = getCookies('salut_lang').split('=')[1] || 'en'

    const login = (username, password, handleModal) => {
        axios.post('https://massaccess.net:9060/public/comein', {'email': username, 'password': password, langs:langs}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + getCookies('sl-auth-token').split('=')[1],
            }
        })
            .then((data) => {
                setCookie('auth_token', data.data.data.token, 86400)
                initAuth(data.data.data.token)
                setIsAuthenticated(true)
                if (handleModal) {
                    handleModal()
                }
            })
            .catch(err => {
                setStatusLogin(err)
            })
    }
    const confirm = (guid) => {
        let defaultTokenSl = getCookies('sl-auth-token').split('=')[1]

        axios.post('https://massaccess.net:9060/public/confirm', {'guid': guid}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + defaultTokenSl,
            }
        })
            .then((data) => {
                setCookie('auth_token', data.data.data.token, 86400)
                initAuth(data.data.data.token)
                setIsAuthenticated(true)
                setStatusConfirm(data)
            })
            .catch(err => {
                setStatusConfirm(err)
            })
    }

    function getLanguage() {
        axios.post('https://massaccess.net:9060/public/langs',{}, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + getCookies('sl-auth-token').split('=')[1],
            }
        })
            .then((data) => {
                setLangsList(data.data.data)
            })
            .catch(err => {
                console.log('Error 1')
            })
    }


    function registration(email, password) {
        let lang = getCookies('salut_lang').split('=')[1] || 'en'

        let data_reg = {
            "email": email,
            "password": password,
            "lang": lang
        }

        setStatusRegistration('')
        axios.post('https://massaccess.net:9060/public/registr', data_reg, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + getCookies('sl-auth-token').split('=')[1],
            }
        })
            .then((response) => {
                setStatusRegistrationMessages(response.data.data)
                setStatusRegistration('ok')
            })
            .catch((data) => {
                setStatusRegistrationMessages(data.response.data.message)
                setStatusRegistration('err')
            })
    }

    function forgotPass(guid, password) {
        let data_reg = {
            "guid": guid,
            "password": password
        }
        setStatusForgot('')
        axios.post('https://massaccess.net:9060/public/restorepassword', data_reg, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: 'Bearer ' + getCookies('sl-auth-token').split('=')[1],
            }
        })
            .then((response) => {
                if (response.data.data.token) {
                    setCookie('auth_token', response.data.data.token, 86400)
                    initAuth(response.data.data.token)
                } else {
                    /* модальное окно с ошибкой и статусом ок*/
                    setStatusForgot('ok')
                }
            }).catch((data) => {
            setStatusForgot('err')
        })
    }

    const socialLogin = (handleModal) => {
        const popup = window.open(
            'https://oauth.vk.com/authorize?client_id=51636831&display=page&redirect_uri=https://massaccess.net:9060/content/vkauthcapture&response_type=code&scope=email,phone_number',
            '_blank',
            'resizable=yes, scrollbars=yes, titlebar=yes, width=800, height=600'
        )
        let popTimer = window.setInterval(() => {
            if (popup.closed !== false) {
                let dataVk = {}
                window.clearInterval(popTimer)
                if (getCookies("vk__name").length > 0) {
                    dataVk.name = getCookies("vk__name").split('=')[1].split(' ')[0]
                    dataVk.surname = getCookies("vk__name").split('=')[1].split(' ')[1]
                }
                dataVk.email = getCookies("vk__mail").split('=')[1]
                dataVk.langs = getCookies('salut_lang').split('=')[1] || 'en'
                dataVk.phone = getCookies("vk__phone").split('=')[1]
                dataVk.social_id = getCookies("vk__id").split('=')[1]
                dataVk.social_token = getCookies("vk__token").split('=')[1]
                let tokenVk = getCookies('sl-auth-token').split('=')[1]

                axios.post('https://massaccess.net:9060/public/comeinvk', dataVk, {
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json',
                        Authorization: 'Bearer ' + tokenVk,
                    }
                })
                    .then((response) => {
                        if (response) {
                            setCookie('auth_token', response.data.data.token, 86400)
                            initAuth(response.data.data.token)
                            setIsAuthenticated(true)
                            if (handleModal) {
                                handleModal()
                            }
                        }
                    })
            }
        }, 100)
    }

    const logout = () => {
        setIsAuthenticated(null)
        setCookie('auth_token', '', -1)
        setCookie('vk__token', '', -1)
    }

    return (
        // Предоставьте состояние авторизации и функции входа/выхода в контекстное API
        <AuthContext.Provider value={{
            isAuthenticated,
            login,
            registration,
            logout,
            socialLogin,
            getBasketTicket,
            statusRegistrationMessages,
            token,
            avatar,
            name,
            surname,
            phone,
            dateBirth,
            clientSex,
            email,
            currenciesIso,
            idUser,
            balance,
            outBalance,
            allTicketBasket,
            currenciesId,
            listCurrency,
            randomFriends,
            ticketBackground,
            drawCurrent,
            ticketNumber,
            ticketCurrentBuy,
            defaultTokenSl,
            isMobile,
            getClientSingle,
            statusRegistration,
            forgotPass,
            statusForgot,
            setStatusForgot,
            setToken,
            confirm,
            statusConfirm,
            statusLogin,
            isGoMain,
            setIsGoMain,
            currencyName,
            getDrawCurrent,
            getMyFriend,
            myFriends,
            getCookies,
            setStatusRegistration,
            langUser,
            langsList,
        }}>
            {children}
        </AuthContext.Provider>
    )
}
