import React from 'react';
import {useTranslation} from "react-i18next";

function Edition() {
    const {t} = useTranslation();

    return (
        <div className="content_block section">
            <div className="content card_section">
                <div className="card_block_title">{t('Draw results')}</div>
            </div>
        </div>
    );
}

export default Edition;
