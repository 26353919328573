import React, {useState, useEffect, useContext} from 'react';
import Select from 'react-select';
import {AuthContext} from "../../../../component/Auth/AuthContext";
import {useTranslation} from "react-i18next";

export interface Option {
    className: string,
    handleModalLogin: any
    select?: any
}

const SelectWithSearchFriends = (props: Option) => {
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState([]);
    const {token, isAuthenticated} = useContext(AuthContext);
    const {t} = useTranslation();

    useEffect(() => {
        if (isAuthenticated) {
            const searchOnServer = async () => {
                try {
                    const response = await fetch("https://massaccess.net:9060/public/clientsfriendssearchget",
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'accept': 'application/json',
                                Authorization: 'Bearer ' + token,
                            },
                            body: JSON.stringify({name: searchValue})
                        });

                    const data = await response.json();
                    if (data.data) {
                        data.data.map((item: any) => {
                            item.label = item.name || item.email
                            item.value = item.client_id
                        })
                        setOptions(data.data);
                    }

                } catch (error) {
                    console.error('Ошибка при выполнении поиска', error);
                }
            };


            if (searchValue.trim() !== '') {
                searchOnServer();
            } else {
                setOptions([]);
            }
        } else {
            if (searchValue != '') {
                props.handleModalLogin()
            }
        }
    }, [searchValue]);
    const getOptionLabel = (option: any) => {
        return (
            <div className="elementSearchLine">
                <div className="avatar"><img src={option.avatar}/></div>
                <div className="text">{option.label}</div>
            </div>
        );
    };


    const handleChange = (selectedOption: any) => {
        if (props.select) {
            selectedOption.name = selectedOption.name || selectedOption.email
            props.select(selectedOption)
        }
    };

    return (
        <Select
            options={options}
            placeholder={t('E-mail, phone number, full name')}
            isSearchable={true}
            onInputChange={(inputValue) => setSearchValue(inputValue)}
            onChange={handleChange}
            formatOptionLabel={getOptionLabel}
            className={props.className}
            classNamePrefix={'selectFriend'}
            value={''}
        />
    );
};

export default SelectWithSearchFriends;
