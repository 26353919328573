import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../component/Auth/AuthContext";
import {Modal} from "../../component/Modal/Default/Modal";
import {useForm} from "react-hook-form";

type Inputs = {
    email: string;
    password: string;
    password_repeat: string
};

function Registration() {
    const {t} = useTranslation();
    const nav = useNavigate();
    const {
        registration,
        statusRegistration,
        statusRegistrationMessages,
        setStatusRegistration
    } = useContext(AuthContext);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [statusError, setStatusError] = useState('');

    const {
        register,
        getValues,
        handleSubmit,
        formState: {errors}
    } = useForm<Inputs>();

    const onSubmit = (data: any) => {
        registration(data.email, data.password)

    };

    const type = ''

    const handleModal = () => {
        setStatusRegistration('')
        setIsSuccess(false)
        nav('/')
    }
    const handleModalError = () => {
        setIsError(!isError)
    }
    useEffect(() => {
        if (statusRegistration === 'err') {
            setStatusError(statusRegistrationMessages)
            setIsError(true)
        }
        if (statusRegistration === 'ok') {
            setStatusError(statusRegistrationMessages)
            setIsSuccess(true)
        }
    }, [statusRegistration])

    return (
        <div className="content_block">
            <div className="section registration">
                <div className="content">
                    <div className="modal_reg">
                        <div className="title_reg">{t('Registration')}</div>
                        <div className="sup_description_reg">{t('Already have an account?')}</div>
                        <Link to="/" className="enter_reg">{t('Sign in')}</Link>
                        <form className="form_reg" onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_block_reg">
                                <input
                                    id="email"
                                    className={errors.email ? 'error' : ''}
                                    placeholder="Email"
                                    {...register('email', {
                                        required: "You must specify a email",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Entered value does not match email format"
                                        }
                                    })}
                                    type="email"
                                />
                                {errors.email && <span role="alert">{errors.email.message}</span>}
                            </div>
                            <div className="input_block_reg">
                                <input
                                    type="password"
                                    className={errors.password ? 'error' : ''}
                                    placeholder={t('Password')}
                                    {...register('password', {
                                        required: "You must specify a password",
                                        minLength: {
                                            value: 6,
                                            message: "Password must have at least 6 characters"
                                        }
                                    })}
                                />
                                {errors.password && <p>{errors.password.message}</p>}
                            </div>
                            <div className="input_block_reg">
                                <input
                                    type="password"
                                    className={errors.password_repeat ? 'error' : ''}
                                    placeholder={t('Repeat password')}
                                    {...register('password_repeat', {
                                        validate: value => {
                                            return value === getValues('password') || "The passwords do not match"
                                        }
                                    })}
                                />
                                {errors.password_repeat && <p>{errors.password_repeat.message}</p>}
                            </div>
                            <button className="btn btn_bg btn_reg" type="submit">{t('Register')}</button>
                        </form>
                        <div
                            className="reg_description">{t('By clicking on the “Register” button, I confirm that I am over 18 years old and I accept the terms of the Offer Agreement')}
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isSuccess} onClose={handleModal} type={type}>
                <div className="modal_content_title">{t('Are you registered')}</div>
                <div className="modal_content_info">
                    {t('You have successfully registered, to continue, please confirm your email, we have already sent you a confirmation link')}
                </div>
                <div className="modal_profile_btn btn_bg btn" onClick={handleModal}>{t('Ok')}</div>
            </Modal>
            <Modal isOpen={isError} onClose={handleModalError} type={type}>
                <div className="modal_content_title">{t('Error')}</div>
                <div className="modal_content_info">
                    {statusError}
                </div>
                <div className="modal_content_info">
                </div>
                <div className="modal_profile_btn btn_bg btn" onClick={handleModalError}>{t('Ok')}</div>
            </Modal>
        </div>
    );
}

export default Registration;
